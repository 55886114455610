import React, {useEffect, useState} from "react";

import moment from "moment";

import API from "../../Generic/API";
import DownloadCsv from "../../Generic/DownloadCsv";
import Formatter from "../../Style/Formatter";

import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

import {MobileDatePicker} from "@mui/x-date-pickers";

/**
 * BackupCreditCards component.
 *
 * @returns {*}
 * @constructor
 */
function BackupCreditCards() {
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [recordsLength, setRecordsLength] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const drawerWidth = 280;

    /**
     * Loads all of the funding results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async (page, rows, sortOrder) => {
        setLoading(true);

        const defaultEndDate = new Date();
        let currentDay = String(defaultEndDate.getDate()+1).padStart(2, '0');
        let currentMonth = String(defaultEndDate.getMonth()+1).padStart(2,"0");
        let currentYear = defaultEndDate.getFullYear();

        const formattedStartDate = formatDate(startDate, '2022-01-01');
        const formattedEndDate = formatDate(endDate, `${currentYear}-${currentMonth}-${currentDay}`);
        const response = await API.send(`pay-at-close/reporting/backup-credit-cards/${formattedStartDate}/${formattedEndDate}`);

        setRecords(response);
        setRecordsLength(response.length);
        setLoading(false);

        setTimeout(() => {
            if (window.hljs) {
                window.hljs.highlightAll()
            }
        }, 500);
    };

    const formatDate = (dateToFormat = null, defaultDate) => {
        if (!dateToFormat || dateToFormat.length === 0) {
            return defaultDate;
        }

        const dateRegex = /([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g;
        const regexResults = dateRegex.exec(dateToFormat.toString());

        if (regexResults && regexResults.length === 4) {
            return `${regexResults[3]}-${regexResults[1]}-${regexResults[2]}`;
        }

        return defaultDate;
    }

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'drawer__page'}>
            <Drawer
                    className={'drawer'}
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                    }}
                >
                <div className={'columns__1'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Backup Credit Card Charges</h3>
                            <p className={'text__muted'}>Please use the following filters to customize your report.</p>
                        </div>
                    </div>

                    <div>
                        <div className={'d__inline-block mr__3'}>
                            <MobileDatePicker
                                label="Start Date"
                                format="MM/DD/YYYY"
                                inputFormat="MM/DD/YYYY"
                                value={startDate}
                                onChange={(value) => setStartDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>

                        <div className={'d__inline-block mr__3'} style={{ marginTop: '1em' }}>
                            <MobileDatePicker
                                label="End Date"
                                format="MM/DD/YYYY"
                                inputFormat="MM/DD/YYYY"
                                value={endDate}
                                onChange={(value) => setEndDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </div>
                    <div className={'paper__footer'}>
                        <Button onClick={getResults} color={'primary'} variant={'contained'}>Run</Button>
                    </div>
                </div>
            </Drawer>
            <div className={'columns__1 backup-cards-table'}>
                <Paper className={'table__block'}>
                    <div className={'block__heading block__heading--bordered'}>
                        <div className={'d-flex__justify'}>
                            <div>
                                <h3 className={'text__bold'}>Credit Card Charges {!isLoading ? `(${recordsLength})` : ''}</h3>
                                <p className={'text__muted'}>Your results are displayed below.</p>
                            </div>

                            <div>
                                <TextField
                                    label="Search..."
                                    variant="outlined"
                                    sx={{width: '280px'}}
                                    value={search}
                                    onChange={(event) => setSearch(event.target.value)}
                                />
                                <IconButton
                                    onClick={() => {
                                        document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                    }}
                                    style={{marginTop: '0.3em'}}
                                >
                                    <DownloadIcon/>
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    <TableContainer component={'div'} className={'transactions__list'}>
                        {isLoading && <LinearProgress/>}
                        <MUIDataTable
                            data={records}
                            className={'table__grid'}
                            options={
                                {
                                    searchText: search,
                                    selectableRows: "none",
                                    responsive: 'standard',
                                    count: recordsLength,
                                    rowsPerPage: 50,
                                    rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                    filter: false,
                                    search: false,
                                    print: false,
                                    download: true,
                                    viewColumns: true,
                                    customToolbar: null,
                                    fixedHeader: true,
                                    tableBodyHeight: 'calc(100vh - 300px)',
                                    serverSide: false,
                                    customRowRender: (data, i) => {
                                        const charge = records[i];

                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <a href={`/transaction/${charge.transaction_id}`}>
                                                        {charge.client_name}
                                                    </a>
                                                </TableCell>
                                                <TableCell>
                                                    {charge.processor_name}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(charge.updated_at, 'YYYY-MM-DDThh:mm:ss.000000Z').format('MMMM Do YYYY, h:mm:ss a')}
                                                </TableCell>
                                                <TableCell>
                                                    {charge.status}
                                                </TableCell>
                                                <TableCell>
                                                    {charge.details}
                                                </TableCell>
                                                <TableCell>
                                                    {Formatter.currency(charge.amount)}
                                                </TableCell>
                                                <TableCell>
                                                    {charge.merchant_code}
                                                </TableCell>
                                                <TableCell>
                                                    <pre>
                                                        <code className={"language-json"}>{charge.merchant_error_message}</code>
                                                    </pre>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                }
                            }
                            columns={[
                                {
                                    name: "client_name",
                                    label: "Client Name",
                                    options: {
                                        sort: true
                                    }
                                }, {
                                    name: "processor_name",
                                    label: "Processor Name",
                                    options: {
                                        sort: true
                                    }
                                }, {
                                    name: "updated_at",
                                    label: "Date",
                                    options: {
                                        sort: true
                                    }
                                }, {
                                    name: "status",
                                    label: "Status",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "details",
                                    label: "Details",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "amount",
                                    label: "Amount",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "merchant_code",
                                    label: "Merchant Code",
                                    options: {
                                        sort: true
                                    }
                                }, {
                                    name: "merchant_error_message",
                                    label: "Merchant Message",
                                    options: {
                                        sort: true
                                    }
                                },
                            ]}
                        />
                    </TableContainer>
                </Paper>
            </div>
        </div>
    );
}

export default BackupCreditCards;
