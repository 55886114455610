import React, {useEffect, useState} from "react";

import API from "../../../Generic/API";
import {Link} from "react-router-dom";

import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Promotions component.
 *
 * @returns {*}
 * @constructor
 */
function Promotions() {
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRunning, setRunning] = useState(false);

    const drawerWidth = 280;

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // month is 0 indexed
        const day = date.getDate();
        // concatenate the year, month, and day with dashes and zero-pad the month and day if necessary
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      }

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        const getResults = async () => {
            setLoading(true);
            const response = await API.send(`pay-at-close/reporting/promotions`);
            setRecords(response);
            setLoading(false);
        };

        getResults().then(() => true);
    }, []);

    return (
        <div className={'drawer__page'}>
            <div className={'columns__1'}>
                <Paper className={'table__block'}>
                    <div className={'columns__1 gap__0'}>
                        <div className={'block__heading block__heading--bordered'}>
                            <div className={'d-flex__justify'}>
                                <div>
                                    <h3 className={'text__bold'}>Promotions {!isLoading ? `(${records.length})` : ''}</h3>
                                    <p className={'text__muted'}>Your results are displayed below.</p>
                                </div>

                                <div>
                                    <TextField
                                        label="Search..."
                                        variant="outlined"
                                        sx={{width: '280px'}}
                                        value={search}
                                        onChange={(event) => setSearch(event.target.value)}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                        }}
                                        style={{marginTop: '0.3em'}}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <TableContainer component={'div'} className={'transactions__list'}>
                            {isLoading && <LinearProgress />}
                            <MUIDataTable
                                className={'table__grid'}
                                data={records}
                                options={
                                    {
                                        searchText: search,
                                        selectableRows: "none",
                                        responsive: 'standard',
                                        rowsPerPage: 100,
                                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                        filter: false,
                                        search: false,
                                        print: false,
                                        download: true,
                                        viewColumns: true,
                                        customToolbar: null,
                                        fixedHeader: true,
                                        tableBodyHeight: 'calc(100vh - 260px)',
                                        customRowRender: (data, i) => {
                                            const record = records[i];
                                            const currencyFormatter = Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            });

                                            return (
                                                <TableRow key={record.companykey}>
                                                    <TableCell>
                                                        <a href={`/account/${record.companykey}`} target="_blank" rel="noopener noreferrer">
                                                            {record.companykey}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell>
                                                        {record.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {record.type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {currencyFormatter.format(record.promotional_fee)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDate(record.end_date)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {record.type === 'count' ? record.remaining_inspections : 'N/A'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {record.inspections_applied}
                                                    </TableCell>
                                                    <TableCell>
                                                        {record.created_by}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDate(record.created_on)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    }
                                }
                                columns={[
                                    {
                                        name: "companykey",
                                        label: "Company Key",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "name",
                                        label: "Promotion Name",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "type",
                                        label: "Type",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "promotional_fee",
                                        label: "Promotional Fee (in Dollars)",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "end_date",
                                        label: "End Date",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "remaining_inspections",
                                        label: "Remaining Inspections",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "inspections_applied",
                                        label: "Inspections Applied to Promo",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "created_by",
                                        label: "Created By",
                                        options: {
                                            sort: true,
                                        }
                                    },{
                                        name: "created_on",
                                        label: "Created On",
                                        options: {
                                            sort: true,
                                        }
                                    },
                                ]}
                            />
                        </TableContainer>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default Promotions;
