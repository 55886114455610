import React, {useEffect, useState} from "react";

import {useAuth} from "../Providers/Authentication";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import {Link, NavLink, matchPath} from "react-router-dom";

/**
 * Navigation component.
 *
 * @returns {*}
 * @constructor
 */
function Navigation(props) {
    const {authenticated, logout} = useAuth();

    const isPublic = !!matchPath({
        path: "/payment/:id",
        exact: true,
        strict: false
    }, window.location.pathname);

    /**
     * Handles user logout.
     */
    const handleLogout = () => {
        logout().then(() => {
            window.location = '/';
        });
    };


    /**
     * Returns the logo image link.
     *
     * @returns {*}
     * @constructor
     */
    const LogoLink = () => {
        return (
            <h4>
                <img src={'/favicon.ico'} alt={'Guardian Inspection Payments'}/><b>Guardian</b>
                <span className={'navigation__separator'}/> Inspection Payments
            </h4>
        );
    };

    return (
        <div>
            <AppBar component="nav" className={'navigation__container'}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => true}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>

                    {!isPublic ?
                        <Link to={'/'} className={'navigation__logo'}>
                            <LogoLink/>
                        </Link> : <div className={'navigation__logo'}>
                            <LogoLink/>
                        </div>
                    }

                    {authenticated && !isPublic &&
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <NavLink to="/funding" className={'nav__item'}>Funding</NavLink>
                        <NavLink to="/accounts" className={'nav__item'}>Accounts</NavLink>
                        <NavLink to="/transactions" className={'nav__item'}>Transactions</NavLink>
                        <NavLink to="/reporting" className={'nav__item'}>Reporting</NavLink>
                        <Button sx={{color: '#fff'}} onClick={handleLogout}>
                            Logout
                        </Button>
                    </Box>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Navigation;
