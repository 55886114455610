import React, {useState} from "react";

import TransactionsTable from "./TransactionsTable";
import TransactionsTableClient from "./TransactionsTableClient";

/**
 * CancelledTransactions component.
 *
 * @returns {*}
 * @constructor
 */
function CancelledTransactions(props) {
    const {search, searchText, searches, filter, tableType, filterUpdate, setFilterUpdate, processStatuses} = props;
    const [isLoading, setLoading] = useState(false);

    return (
        tableType === 'server' ?
        <TransactionsTable
            search={search}
            searches={searches}
            filter={filter}
            setLoading={setLoading}
            loading={isLoading}
            processStatuses={processStatuses}
            apiPrefixUrl={'pay-at-close/transactions/cancelled'}
        /> :
        <TransactionsTableClient
            searchText={searchText}
            filter={filter}
            loading={isLoading}
            setLoading={setLoading}
            filterUpdate={filterUpdate}
            setFilterUpdate={setFilterUpdate}
            processStatuses={processStatuses}
            apiPrefixUrl={'pay-at-close/transactions/cancelled'}
        />
    );
}

export default CancelledTransactions;
