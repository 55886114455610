import React from "react";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import CommentIcon from '@mui/icons-material/Comment';
import BusinessIcon from '@mui/icons-material/Business';
import TableContainer from "@mui/material/TableContainer";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

/**
 * Reporting component.
 *
 * @returns {*}
 * @constructor
 */
function Reporting() {
    const navigate = useNavigate();

    return (
        <div className={'columns__1'}>
            <Paper>
                <div className={'columns__1'}>
                    <h3 className={'text__bold'}>Reporting</h3>
                    <div className={'text__muted'}>Please select one of the following reports.</div>
                    <TableContainer component={'div'}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Name</b></TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <Avatar className={'mr__3'}>
                                                <BusinessIcon />
                                            </Avatar>

                                            <div className={'w__100'}>
                                                <h3 className={'text__bold'}>Inspection Companies</h3>
                                                <p>Returns a series of all active Pay at Close accounts.</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button
                                            size={'small'}
                                            variant={'contained'}
                                            onClick={() => navigate('/reporting/companies')}
                                        >View</Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <Avatar className={'mr__3'}>
                                                <TrendingUpIcon />
                                            </Avatar>

                                            <div className={'w__100'}>
                                                <h3 className={'text__bold'}>Inspection Count and Revenue</h3>
                                                <p>Calculates inspection revenue against each account.</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button
                                            size={'small'}
                                            variant={'contained'}
                                            onClick={() => navigate('/reporting/revenue')}
                                        >View</Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <Avatar className={'mr__3'}>
                                                <CreditCardIcon />
                                            </Avatar>

                                            <div className={'w__100'}>
                                                <h3 className={'text__bold'}>Backup Credit Card Charges</h3>
                                                <p>Review backup credit cards charged via the dashboard.</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button
                                            size={'small'}
                                            variant={'contained'}
                                            onClick={() => navigate('/reporting/backup-credit-cards')}
                                        >View</Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <Avatar className={'mr__3'}>
                                                <CommentIcon />
                                            </Avatar>

                                            <div className={'w__100'}>
                                                <h3 className={'text__bold'}>Transaction Notes</h3>
                                                <p>Explore notes related to each transaction.</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button
                                            size={'small'}
                                            variant={'contained'}
                                            onClick={() => navigate('/reporting/notes')}
                                        >View</Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <Avatar className={'mr__3'}>
                                                <LocalOfferIcon />
                                            </Avatar>

                                            <div className={'w__100'}>
                                                <h3 className={'text__bold'}>Promotions</h3>
                                                <p>Reports the accounts with promotions currently running.</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button
                                            size={'small'}
                                            variant={'contained'}
                                            onClick={() => navigate('/reporting/promotions')}
                                        >View</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

export default Reporting;
