import React from "react";

import AccountActions from "./AccountActions";

import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

/**
 * AccountsTable component.
 *
 * @returns {*}
 * @constructor
 */
function AccountsTable(props) {
    const {loading, records, search, onReload} = props;

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {loading && <LinearProgress/>}
            <MUIDataTable
                data={records}
                className={'table__grid'}
                options={
                    {
                        searchText: search,
                        selectableRows: "none",
                        responsive: 'standard',
                        rowsPerPage: 100,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 300px)',
                        customRowRender: (data, i) => {
                            const record = records[i];

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <div className={'d-flex__justify'}>
                                            <div className={'w-100 d-flex__justify'}>
                                                <div>
                                                    <Link to={`/account/${record.name}`}>
                                                        {record.name}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {record.enabled === 'yes' ? <CheckIcon color={'success'}/> : <CloseIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {record.enabled_date}
                                    </TableCell>
                                    <TableCell>
                                        {record.disabled_date}
                                    </TableCell>
                                    <TableCell>
                                        {record.isn_canceled === 'yes' ? <CheckIcon color={'success'}/> : <CloseIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {record.isn_test === 'yes' ? <CheckIcon color={'success'}/> : <CloseIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {record.checking_account_enabled === 'yes' ? <CheckIcon color={'success'}/> : <CloseIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {record.w9_enabled === 'yes' ? <CheckIcon color={'success'}/> : <CloseIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {record.services_opt_in !== undefined ? record.services_opt_in.split(" ").map((item, index) => (
                                            <p key={`services-${index}`}>{item}</p>
                                        )) : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {record.inspection_types_opt_in !== undefined ? record.inspection_types_opt_in.split(" ").map((item, index) => (
                                            <p key={`types-${index}`}>{item}</p>
                                        )) : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {record.funding_status === 'queued' ? <AccessTimeIcon/> : (
                                            record.funding_status === 'verified' ? <CheckIcon color={'success'}/> : (
                                                record.funding_status === 'delivered' ? <HourglassBottomIcon/> : <CloseIcon/>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <AccountActions
                                            record={record}
                                            onReload={onReload}
                                            disabled={loading}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "name",
                        label: "Account",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "enabled",
                        label: "Enabled",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "enabled_date",
                        label: "Enabled Date",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "disabled_date",
                        label: "Disabled Date",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "isn_canceled",
                        label: "ISN Canceled",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "isn_test",
                        label: "ISN Test CK",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "checking_account_enabled",
                        label: "Checking Account Entered",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "w9_enabled",
                        label: "W9 Entered",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "services_opt_in",
                        label: "Services Opt In",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "inspection_types_opt_in",
                        label: "Types Opt In",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "funding_status",
                        label: "Verified?",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "actions",
                        label: "Actions",
                        options: {
                            sort: false,
                        }
                    }
                ]}
            />
        </TableContainer>
    );
}

export default AccountsTable;
