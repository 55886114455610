import React, {useState} from "react";

import API from "../Generic/API";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * TransactionProcessStatusAction component.
 *
 * @returns {*}
 * @constructor
 */
function TransactionProcessStatusAction(props) {
    const {record, onSuccess, processStatuses} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const open = Boolean(anchorEl);

    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Performs an update.
     *
     * @param value
     * @returns {Promise<void>}
     */
    const handleStatusUpdate = async (value) => {
        setLoading(true);

        await API.send(
            `pay-at-close/transaction/${record.id}/process-status`,
            'POST',
            {
                status: value
            }
        );

        if (onSuccess) {
            onSuccess().then(() => setLoading(false));
        }
    };


    return (
        <div>
            <Button
                disabled={isLoading}
                className={'text__plain process__button'}
                variant="link"
                disableElevation
                onClick={(event) => handleMenuOpen(event)}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                {record.funding_status || 'Not Started'}
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            >
            {processStatuses.map((value, index) => (
                <MenuItem key={`process-status-${record.id}-${index}`} onClick={() => {
                    handleMenuClose();
                    handleStatusUpdate(value).then(() => true);
                }}>{value}</MenuItem>
            ))}
            </Menu>
        </div>
    );
}

export default TransactionProcessStatusAction;