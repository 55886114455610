import React, {useEffect, useState} from "react";

import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import {Link} from "react-router-dom";

import MUIDataTable from "mui-datatables";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Tabs from "../Transactions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * TransactionNotes component.
 *
 * @returns {*}
 * @constructor
 */
function TransactionNotes() {
    const [search, setSearch] = useState(localStorage.getItem('notes__search') || '');
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const drawerWidth = 280;

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        const getResults = async () => {
            setLoading(true);
            const response = await API.send(`pay-at-close/notes`);
            setRecords(response);
            setLoading(false);
        };

        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', padding: '1em'}} className={'transactions__heading'}>
                    <div className={'d-flex__justify'}>
                        <div className={'columns__1'}>
                            <h3 className={'text__bold'}>Notes ({records.length})</h3>
                            <div className={'text__muted'}>Your notes are displayed below.</div>
                        </div>

                        <div className={'d-flex__justify'}>
                            <TextField
                                label="Search..."
                                variant="outlined"
                                sx={{width: drawerWidth}}
                                value={search}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setSearch(value);
                                    localStorage.setItem('notes__search', value);
                                }}
                            />
                            <IconButton
                                onClick={() => {
                                    document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                }}
                                style={{marginTop: '0.3em'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </div>
                    </div>
                </Box>

                <div className={'hide__scroll'}>
                    <TableContainer component={'div'} className={'transactions__list'}>
                        {isLoading && <LinearProgress />}
                        <MUIDataTable
                            className={'table__grid'}
                            data={records}
                            options={
                                {
                                    searchText: search,
                                    selectableRows: "none",
                                    responsive: 'standard',
                                    rowsPerPage: 500,
                                    rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                    filter: false,
                                    search: false,
                                    print: false,
                                    download: true,
                                    viewColumns: true,
                                    customToolbar: null,
                                    fixedHeader: true,
                                    tableBodyHeight: 'calc(100vh - 240px)',
                                    customRowRender: (data, i) => {
                                        const record = records[i];

                                        return (
                                            <TableRow key={i}>
                                                <TableCell width={175}>
                                                    <Link to={`/transaction/${record.transaction_id}`}>
                                                        View Transaction
                                                    </Link>
                                                </TableCell>
                                                <TableCell width={175}>
                                                    {Formatter.date(record.created_at, 'MM/DD/YYYY hh:mm A')}
                                                </TableCell>
                                                <TableCell width={175} children={record.created_by}/>
                                                <TableCell dangerouslySetInnerHTML={{__html: record.note}}/>
                                            </TableRow>
                                        )
                                    }
                                }
                            }
                            columns={[
                                {
                                    name: "transaction_id",
                                    label: "Transaction",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "created_at",
                                    label: "Created At",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "created_by",
                                    label: "Created By",
                                    options: {
                                        sort: true,
                                    }
                                }, {
                                    name: "note",
                                    label: "Note",
                                    options: {
                                        sort: true,
                                    }
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

export default TransactionNotes;
