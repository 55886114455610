import React, {Fragment, useEffect, useState} from "react";

import API from "../Generic/API";
import StatusChip from "../Components/StatusChip";

import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {useParams, useNavigate} from "react-router-dom";

/**
 * Charge component.
 *
 * @returns {*}
 * @constructor
 */
function Charge() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [record, setRecord] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [chargeAmount, setChargeAmount] = useState(false);
    const [waiveFee, setWaiveFee] = useState(false);
    const [reason, setReason] = useState('');
    const feeSurcharge = 0.03;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    /**
     * Loads the transaction via the API.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/transaction/${id}`);

        // Handle invalid records.
        if (!response.transaction.id) {
            return navigate('/not-found');
        }

        setRecord(response.transaction);
        updateAmount(waiveFee, response.transaction.amount);
        // setChargeAmount(formatter.format(response.transaction.amount));
        setLoading(false);
    };


    /**
     * Updates the amount based on whether the waive fee checkbox is checked
     *
     * @returns {Promise<void>}
     */
    const updateAmount = async (fee, amount) => {
        const isFeeWaived = fee === true;
        setWaiveFee(isFeeWaived);

        if (isFeeWaived) {
            setChargeAmount(formatter.format(amount));
        } else {
            setChargeAmount(formatter.format(amount * (1 + feeSurcharge)));
        }
    };


    /**
     * Attempts to process the charge.
     *
     * @returns {Promise<void>}
     */
    const doCharge = async (reason, waiveFee) => {
        setLoading(true);

        const response = await API.send(
            `pay-at-close/transaction/${record.id}/charge`,
            'POST',
            {
                reason,
                waiveFee
            }
        );

        if (response.status === 'success') {
            setTimeout(async () => {
                await getResults();
            }, 1000);
        }

        setLoading(false);
    };

    const detectAlerts = () => {
        if (!record || !record.id) {
            return '';
        }

        if (!record.signed_agreement_url) {
            return (
                <Alert severity={'error'}>There is no backup payment method available for this transaction.</Alert>
            )
        }

        if (record.status === 'CLOSING_FELL_THROUGH') {
            return (
                <p>Your charge is being attempted. Please be patient as this page will refresh automatically...</p>
            )
        }

        if (record.status === 'REJECT_NONPAYMENT_CLIENT') {
            return (
                <Alert severity={'error'}>The backup payment method was rejected and could not be processed at this
                    time. Please contact the client to receive updated payment information.</Alert>
            )
        }

        if (record.status === 'CLIENT_PAYMENT_COMPLETE') {
            return (
                <Alert severity={'success'}>The backup payment method was accepted and charged successfully! The
                    transaction status has been updated accordingly.</Alert>
            )
        }

        return null;
    };


    /**
     * Loads the target transaction on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify'}>
                <h3>Pay at Close</h3>
            </div>
            <Paper>
                <div className={'columns__1'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Charge Backup Payment Method</h3>
                            <p className={'text__muted'}>This form can be used to deliver a charge against the backup
                                payment method for this inspection.</p>
                        </div>

                        {record.id && (
                            <div>
                                <b>The current transaction status is:</b> <StatusChip record={record}/>
                            </div>
                        )}
                    </div>

                    {record.id && detectAlerts() === null ?
                    <Fragment>
                        <p>
                            After starting the charge process, the transaction status will be updated accordingly.
                            To begin, please provide a reason in which the transaction fell through and click "Continue".
                        </p>

                        <TextField
                            rows={6}
                            multiline
                            disabled={isLoading}
                            label={'Please specify a reason...'}
                            onChange={(event) => setReason(event.target.value)}
                        />

                        <div className={'text__right'}>
                            Waive 3% fee? <Checkbox value="1" checked={waiveFee} onClick={(e) => updateAmount(e.target.checked, record.amount)} />
                            <Button variant={'contained'} onClick={() => doCharge(reason, waiveFee)}
                                    disabled={!reason || isLoading}>Charge {chargeAmount}</Button>
                        </div>
                    </Fragment>
                    : detectAlerts()}
                </div>
            </Paper>
        </div>
    );
}

export default Charge;
