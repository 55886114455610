import React, {useState} from "react";

import API from "../Generic/API";
import Statuses from "../Generic/Statuses";
import StatusLogIndicator from "./StatusLogIndicator";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useNavigate} from "react-router-dom";

/**
 * PaymentActions component.
 *
 * @returns {*}
 * @constructor
 */
function PaymentActions(props) {
    const {record, onReload} = props;
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isDeleteMenuOpen, setDeleteFormOpen] = useState(false);

    const open = Boolean(anchorEl);

    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Expands the status menu.
     */
    const handleDeleteMenuOpen = () => {
        setDeleteFormOpen(true);
    };


    /**
     * Collapses the status menu.
     */
    const handleDeleteMessageClose = () => {
        setDeleteFormOpen(false);
    };


    /**
     * Updates the status of a transaction.
     */
    const doDelete = async () => {
        setLoading(true);

        await API.send(
            `pay-at-close/payment/${record.id}`,
            'DELETE'
        );

        setLoading(false);
        setDeleteFormOpen(false);

        if(onReload){
            onReload();
        }
    };


    /**
     * Re-sends the payment request email.
     *
     * @returns {Promise<void>}
     */
    const doResend = async () => {
        setLoading(true);

        await API.send(
            `pay-at-close/payment/${record.id}/resend`,
            'POST'
        );

        setLoading(false);

        if(onReload){
            onReload();
        }
    };


    return (
        <div>
            <IconButton onClick={handleMenuOpen}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    doResend();
                }}>Resend Email</MenuItem>

                <MenuItem onClick={() => {
                    window.location.href = `/payment/${record.id}`;
                }}>View Request Form</MenuItem>

                <MenuItem onClick={() => {
                    handleMenuClose();
                    handleDeleteMenuOpen();
                }}>Delete Request</MenuItem>
            </Menu>
            <Dialog open={isDeleteMenuOpen} onClose={handleDeleteMessageClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Confirm Delete</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            Are you sure you'd like to delete this payment request?
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={doDelete} disabled={isLoading}>Yes</Button>
                    <Button onClick={handleDeleteMessageClose} disabled={isLoading}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PaymentActions;