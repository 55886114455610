import React, {useEffect, useState} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from '@mui/icons-material/Download';
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MultipleTransactionActions from "../../Components/MultipleTransactionActions";

/**
 * TransactionsUI component.
 *
 * @returns {*}
 * @constructor
 */
function SearchServer(props) {
    const {searches, setSearches, setSearch, processStatuses} = props;

    const searchTerms = {
        agent_email: 'Agent Email',
        agent_name: 'Agent Name',
        agent_phone: 'Agent Phone',
        amount: 'Amount',
        client_email: 'Client Email',
        client_name: 'Client Name',
        client_phone: 'Client Phone',
        inspector_email: 'Inspector Email',
        inspector_name: 'Inspector Name',
        order_number: 'Order Number',
        title_company_name: 'Title Company Name',
        title_company_phone: 'Title Company Phone',
    }
    const defaultSearches = Object.fromEntries(Object
        .entries(searchTerms)
        .map(([key, value]) => [key, ''])
    );
    const [searchValue, setSearchValue] = useState('');
    const [searchSelect, setSearchSelect] = useState('');
    const [searchText, setSearchText] = useState('Search');
    const [open, setOpen] = useState(false);
    const [editingMultipleTransactions, setEditingMultipleTransactions] = useState(false);

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleEditingMultipleTransactionDialogOpen = () => {
        setEditingMultipleTransactions(true);
    };

    const handleEditingMultipleTransactionDialogClose = () => {
        setEditingMultipleTransactions(false);
    };

    /**
     * Updates the search parameters
     *
     * @param key
     * @param value
     */
    const handleSearchUpdate = (key, value) => {
        const updated = {
            ...defaultSearches,
            [key]: value
        };

        if (key !== '') {
            setSearchText(`Searching for ${searchTerms[key]} = ${value}.`);
        } else {
            setSearchText('Search');
        }

        setSearch(updated);
    };

    useEffect(() => {
        setSearch({
            ...defaultSearches
        });
    }, []);

    return (
            <div>
                <Dialog
                    maxWidth={'lg'}
                    open={open}
                    onClose={handleDialogClose}
                >
                    <DialogTitle>
                        Search Transactions
                    </DialogTitle>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <FormGroup>
                            <InputLabel id="search-select-label">Search Term</InputLabel>
                            <Select
                                labelId="search-select-label"
                                id="search-select"
                                label="Search Term"
                                value={searchSelect}
                                onChange={(event) => setSearchSelect(event.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {Object.entries(searchTerms)
                                    .map((element, index) => {
                                        return <MenuItem key={`search-term-${index}`} value={element[0]}>{element[1]}</MenuItem>;
                                    }
                                )}
                            </Select>
                            <TextField
                                id="search-value"
                                sx={{ marginTop: 1 }}
                                label="Search Value"
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />

                            <Grid container spacing={1} align="center" direction="row">
                                <Grid item xs={6} >
                                    <Button
                                        variant="text"
                                        onClick={((event) => {
                                            handleSearchUpdate(searchSelect, searchValue);
                                            setSearches(searches + 1);
                                            handleDialogClose();
                                        })}
                                    >Search</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="text"
                                        onClick={((event) => {
                                            handleSearchUpdate('', '');
                                            setSearches(searches + 1);
                                            handleDialogClose();
                                        })}
                                    >Clear</Button>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Dialog>
                {editingMultipleTransactions && <MultipleTransactionActions records={[]} onReload={true} onClose={handleEditingMultipleTransactionDialogClose}  processStatuses={processStatuses}/>}
                <Button id="edit_multiple_transactions" variant="text" style={{ display : "none"}} onClick={handleEditingMultipleTransactionDialogOpen}>Edit Multiple Transactions</Button>
                <Button
                    variant="text"
                    onClick={handleDialogOpen}
                >{searchText}</Button>
                <IconButton
                    onClick={() => {
                        document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                    }}
                    style={{marginTop: '0.3em'}}
                >
                    <DownloadIcon/>
                </IconButton>
            </div>
    );
}

export default SearchServer;
