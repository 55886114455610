import React from "react";

/**
 * StatusIndicator component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function StatusLogIndicator(props) {
    const {status} = props;

    /**
     * Returns all error statuses.
     *
     * @returns {string[]}
     */
    const getErrorStatuses = () => {
        return [
            'CANCEL_OTHER',
            'ORDER_DELETED',
            'ORDER_CANCELED',
            'TRANSACTION_CANCELED',
            'CLOSING_FELL_THROUGH',
            'REJECT_NONPAYMENT_TE',
            'CANCEL_NO_SIGNATURE_RECEIVED',
            'CANCELED_PAID_DIRECTLY_TO_INSPECTOR'
        ];
    };


    /**
     * Returns all complete statuses.
     *
     * @returns {string[]}
     */
    const getCompleteStatuses = () => {
        return [
            'CLIENT_PAYMENT_COMPLETE',
            'TE_PAYMENT_COMPLETE',
            'FUNDED'
        ];
    };


    /**
     * Indicates whether or not the transaction is currently marked as complete.
     *
     * @returns {boolean}
     */
    const isComplete = () => {
        return getCompleteStatuses().includes(status);
    };


    /**
     * Indicates whether or not the transaction is currently in error.
     *
     * @returns {boolean}
     */
    const isError = () => {
        return !status || getErrorStatuses().includes(status)
    };


    /**
     * Returns the appropriate chip color.
     *
     * @returns {string}
     */
    const getClassName = () => {
        return `pac-status ${isError() ? `pac-status--error` : `${isComplete() ? `pac-status--complete` : ``}`}`;
    };

    return (
        <div
            className={getClassName()}
            title={status}
        />
    );
}

export default StatusLogIndicator;