import React, {Fragment, useState} from "react";

import API from "../../Generic/API";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    FormLabel,
    ToggleButtonGroup,
    ToggleButton,
    TextField,
    FormHelperText,
    Alert,
    Collapse,
    IconButton,
    CircularProgress
} from '@mui/material';

/**
 * Promotions component.
 *
 * @returns {*}
 * @constructor
 */
function Promotions(props) {
    const {record, promotions, pricing, disabled, onReload} = props;
    const [promos, setPromos] = useState(promotions);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [addPromotionMenuOpen, setAddPromotionMenuOpen] = useState(false);
    const [deletePromotionMenuOpen, setDeletePromotionMenuOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [promotionAction, setPromotionAction] = useState('add');
    const [currPromotionId, setCurrPromotionId] = useState(null);
    const [addPromotionError, setAddPromotionError] = useState(false);
    const [addPromotionErrorMessage, setAddPromotionErrorMessage] = useState('');
    const [enablePromotions, setEnablePromotions] = useState(pricing?.PAC_PROMOTION.value === 'yes');
    const [submissionFee, setSubmissionFee] = useState(pricing?.PAC_ISN_FEE.value ?? 50);
    const [inspectionFee, setInspectionFee] = useState(pricing?.PAC_SUBMISSION_FEE.value ?? 0);
    const [minimumDollarThreshold, setMinimumDollarThreshold] = useState(pricing?.PAC_MINIMUM_DOLLAR_THRESHOLD?.value ?? 0);
    const [maximumDollarThreshold, setMaximumDollarThreshold] = useState(pricing?.PAC_MAXIMUM_DOLLAR_THRESHOLD?.value ?? 2000);
    const [changeMade, setChangeMade] = useState(false)
    const [showProgress, setShowProgress] = useState(false)
    const [formValues, setFormValues] = useState({
        promotionName: '',
        promotionType: 'date',
        startingDate: '',
        endingDate: '',
        numInspections: '',
        promoFee: ''
    });

    const open = Boolean(anchorEl);

    // Edit Promotion Functions
    // const handleEditPromotionMenuOpen = () => {
    //     setEditPromotionMenuOpen(true);
    // }

    // const handleEditPromotionMenuClose = () => {
    //     setEditPromotionMenuOpen(false);
    // }

    // const handleEditPromotionMenuSave = async () => {
    //     setLoading(true)

    //     onReload()
    //     setEditPromotionMenuOpen(false);

    //     setLoading(false)
    // }

    const handleChangeEnablePromotion = (event, value) => {
        if (value == null)
            return
        setEnablePromotions(value);
        setChangeMade(true)
    }

    // Delete Promotion Functions
    const handleDeletePromotionMenuOpen = (id) => {
        setCurrPromotionId(id)
        setDeletePromotionMenuOpen(true);
    }

    const handleDeletePromotionMenuClose = () => {
        setCurrPromotionId(null)
        setDeletePromotionMenuOpen(false);
    }

    /**
     * Deletes a promotion from the table
     *
     * @returns {Promise<void>}
     */
    const handleDeletePromotion = async () => {
        setLoading(true)

        await API.send(`pay-at-close/account/${record.name}/delete-promotion`, 'POST', {
            currPromotionId
        });

        await onReload();
        setDeletePromotionMenuOpen(false);

        setLoading(false)
    }

    // Add Promotion Functions
    const handleEditPromotionMenuOpen = (promotion) => {
        setAddPromotionMenuOpen(true);
        setPromotionAction('edit');
        setDialogTitle('Edit ' + promotion.type.charAt(0).toUpperCase() + promotion.type.slice(1) + ' Promotion');
        setCurrPromotionId(promotion.id);

        setFormValues({
            promotionId: promotion.id,
            promotionName: promotion.name,
            promotionType: promotion.type,
            startingDate: formatDate(promotion.startDate),
            endingDate: formatDate(promotion.endDate),
            numInspections: promotion.numInspections,
            promoFee: promotion.promotionalFee
        });
    }

    // Add Promotion Functions
    const handleAddPromotionMenuOpen = () => {
        setAddPromotionMenuOpen(true);
        setPromotionAction('add');
        setDialogTitle('Add New Promotion');
    }

    const handleAddPromotionMenuClose = () => {
        setAddPromotionMenuOpen(false);
        setFormValues({
            promotionName: '',
            promotionType: 'date',
            startingDate: '',
            endingDate: '',
            numInspections: '',
            promoFee: ''
        })
        setAddPromotionError(false)
    }

    const savePricingInfo = async () => {
        setLoading(true);
        setShowProgress(true);

        const temp = await API.send(`pay-at-close/account/${record.name}/pricing`, 'POST', {
            enablePromotions,
            submissionFee,
            inspectionFee,
            pricing,
            minimumDollarThreshold,
            maximumDollarThreshold
        });

        setChangeMade(false);
        setShowProgress(false);
        setLoading(false);
    }

    /**
     * Adds a promotion to the table
     *
     * @returns {Promise<void>}
     */
    const handleAddPromotionMenuSave = async () => {
        setLoading(true);

        let errorMessage = '';
        let error = false;

        switch(''){
            default:
                break;
            case(formValues.promotionName):
            case(formValues.promotionType):
            case(formValues.startingDate):
            case(formValues.endingDate):
            case(formValues.promoFee):
                error = true;
                errorMessage = 'You are missing required information.';
                break;
            case(formValues.numInspections):
                if(formValues.promotionType === 'count'){
                    error = true;
                    errorMessage = 'You are missing the # of inspections.';
                }
                break;
        }

        if (formValues.promoFee < 0 || formValues.numInspections < 0) {
            error = true;
            errorMessage += ' Your promotional fee or # of inspections cannot be less than zero.';
        }

        if (formValues.endingDate && new Date(formValues.startingDate) > new Date(formValues.endingDate)) {
            error = true;
            errorMessage += ' Your starting date cannot be after your end date.';
        }

        setAddPromotionErrorMessage(errorMessage);

        // Show an error for invalid inputs and return
        if(error)
        {
            setAddPromotionError(true)
            setLoading(false)
            return;
        }

        // Hit the API with the new promotions values
        const response = await API.send(`pay-at-close/account/${record.name}/promotion`, 'POST', {
            ...formValues
        });

        if (response.status === 'error') {
            setAddPromotionError(true);
            setLoading(false);
            setAddPromotionErrorMessage(response.message);
        } else {
            await onReload();
            handleAddPromotionMenuClose();
        }

        setLoading(false)
    }

    const handleChangePromotionType = (event, value) => {
        if (value == null)
            return
        setFormValues({
            ...formValues,
            promotionType: value
            });
    }

    const handleChangeAddPromotionName = (event) => {
        setFormValues({
          ...formValues,
          promotionName: event.target.value
        });
      };

      const handleChangeStartingDate = (event) => {
        setFormValues({
          ...formValues,
          startingDate: event.target.value
        });
      };

      const handleChangeEndingDate = (event) => {
        setFormValues({
          ...formValues,
          endingDate: event.target.value
        });
      };

      const handleChangeNumInspections = (event) => {
        setFormValues({
          ...formValues,
          numInspections: event.target.value
        });
      };

      const handleChangePromoFee = (event) => {
        setFormValues({
          ...formValues,
          promoFee: event.target.value
        });
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // month is 0 indexed
        const day = date.getDate();
        // concatenate the year, month, and day with dashes and zero-pad the month and day if necessary
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      }


    return (
        <Paper>
            <div className={'columns__1'}>
                <div>
                    <h3 className={'text__bold'}>Pricing Information</h3>
                </div>
                <div className={'columns__3'}>
                    <FormControl>
                        <FormLabel><b>Submission Fee</b></FormLabel>
                        <TextField
                            sx={{maxWidth: 'sm'}}
                            type='number'
                            id="submission-fee"
                            defaultValue={submissionFee}
                            disabled={isLoading}
                            onChange={(event) => {
                                setSubmissionFee(event.target.value);
                                setChangeMade(true);
                            }}
                        />
                        <FormHelperText>To Company in $</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel><b>Inspection Fee</b></FormLabel>
                        <TextField
                            sx={{maxWidth: 'sm'}}
                            type='number'
                            id="inspection-fee"
                            defaultValue={inspectionFee}
                            disabled={isLoading}
                            onChange={(event) => {
                                setInspectionFee(event.target.value);
                                setChangeMade(true);
                            }}
                        />
                        <FormHelperText>To Client in $</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel><b>Minimum order size (in $) required to offer FlexFund </b></FormLabel>
                        <TextField
                            sx={{maxWidth: 'sm'}}
                            type='number'
                            id="minimum-order-threshold"
                            defaultValue={minimumDollarThreshold}
                            disabled={isLoading}
                            onChange={(event) => {
                                setMinimumDollarThreshold(event.target.value);
                                setChangeMade(true);
                            }}
                        />
                        <FormHelperText>FlexFund will be offered if the dollar amount of the order exceeds this amount.</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel><b>Maximum order size (in $) required to offer FlexFund </b></FormLabel>
                        <TextField
                            sx={{maxWidth: 'sm'}}
                            type='number'
                            id="maximum-order-threshold"
                            defaultValue={maximumDollarThreshold}
                            disabled={isLoading}
                            onChange={(event) => {
                                setMaximumDollarThreshold(event.target.value);
                                setChangeMade(true);
                            }}
                        />
                        <FormHelperText>Guardian charges 10% for all orders at or above $2000 that include FlexFund instead of the flat fee for order totals below that amount. FlexFund will be offered if the dollar amount of the order is below this amount.</FormHelperText>
                    </FormControl>
                    <div className={'columns__2'}>
                        <FormControl>
                            <FormLabel><b>Enable Promotional Pricing?</b></FormLabel>
                            <ToggleButtonGroup
                                    exclusive
                                    value={enablePromotions}
                                    disabled={isLoading}
                                    onChange={(event, value) => handleChangeEnablePromotion(event, value)}
                                    sx={{marginTop: '5px'}}
                                    >
                                    <ToggleButton
                                        value={true}
                                        children={'Yes'}
                                        />
                                    <ToggleButton
                                        value={false}
                                        children={'No'}
                                        />
                            </ToggleButtonGroup>
                        </FormControl>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl>
                                <Button disabled={isLoading || !changeMade} onClick={savePricingInfo} variant="contained">
                                    Save Pricing Info
                                </Button>
                            </FormControl>
                            {showProgress &&
                                <CircularProgress size={24} sx={{ marginLeft: '8px' }}/>
                            }
                        </div>
                    </div>
                </div>
                {enablePromotions &&

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Promotion Type</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Starting Date</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Ending Date</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}># Pac Inspections Eligble for Promotional Fee</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Promotional Fee (to company in $)</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {promotions === undefined || promotions === null || promotions.length === 0 ?
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={7}>
                                    No Promotions Found
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {promotions.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.type === 'count' ? 'By Volume' : 'By Date'}</TableCell>
                                    <TableCell align="center" sx={{ minWidth: '100px' }}>{formatDate(row.startDate)}</TableCell>
                                    <TableCell
                                        sx={{ minWidth: '100px' }}
                                        align="center"
                                        >
                                        {formatDate(row.endDate)}
                                    </TableCell>
                                    {row.type !== 'date' ?
                                        <TableCell align="center">{row.numInspections}</TableCell>
                                        :
                                        <TableCell align="center">N/A</TableCell>
                                    }
                                    <TableCell align="center">{row.promotionalFee}</TableCell>
                                    <TableCell align="center">
                                        <Button>
                                            <EditIcon disabled={isLoading} onClick={() => handleEditPromotionMenuOpen(row)} />
                                        </Button>
                                        <Button disabled={isLoading} onClick={() => handleDeletePromotionMenuOpen(row.id)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        }
                    </Table>
                }
            </div>
            {enablePromotions &&
                <Button
                    variant='contained'
                    onClick={handleAddPromotionMenuOpen}
                    sx={{ marginTop: '8px' }}
                    disabled={isLoading}
                >
                    Add Promotion
                </Button>
            }
            {/* Modal for Add Promotion */}
            <Dialog maxWidth='md' open={addPromotionMenuOpen} onClose={handleAddPromotionMenuClose}>
                <DialogTitle>
                    <div className={'text__bold'}>{dialogTitle}</div>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            {promotionAction === 'add' ?
                            <ToggleButtonGroup
                                exclusive
                                disabled={isLoading}
                                value={formValues.promotionType}
                                onChange={(event, value) => handleChangePromotionType(event, value)}
                                >
                                <ToggleButton
                                    size={'small'}
                                    value={'date'}
                                    children={'By Date'}
                                />
                                <ToggleButton
                                    size={'small'}
                                    value={'count'}
                                    children={'By Volume'}
                                    />
                            </ToggleButtonGroup>
                            : ''}
                        </div>
                        <div className={'columns__4'}>
                            <FormControl>
                                <FormLabel><b>Promotion Name</b></FormLabel>
                                <TextField onChange={handleChangeAddPromotionName} value={formValues.promotionName} id="promo-name"/>
                            </FormControl>
                            <FormControl>
                                <FormLabel><b>Starting Date</b></FormLabel>
                                <TextField onChange={handleChangeStartingDate} defaultValue={formValues.startingDate} type='date' id="starting-date"/>
                            </FormControl>
                            <FormControl>
                                <FormLabel><b>Ending Date</b></FormLabel>
                                <TextField onChange={handleChangeEndingDate} defaultValue={formValues.endingDate} type='date' id="ending-date"/>
                            </FormControl>
                            {formValues.promotionType === 'count' ?
                                <FormControl>
                                    <FormLabel><b># of Inspections</b></FormLabel>
                                    <TextField onChange={handleChangeNumInspections} value={formValues.numInspections} type='number' id="num-inspections"/>
                                </FormControl>
                            : '' }
                            <FormControl>
                                <FormLabel><b>Promotional Fee</b></FormLabel>
                                <TextField onChange={handleChangePromoFee} value={formValues.promoFee} type='number' id="promo-fee"/>
                                <FormHelperText>To company in $</FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <Collapse in={addPromotionError}>
                                <Alert
                                severity="error"
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAddPromotionError(false);
                                        setAddPromotionErrorMessage('');
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                >
                                {addPromotionErrorMessage ?? 'Error: Invalid Information for a new Promotion'}
                                </Alert>
                            </Collapse>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleAddPromotionMenuClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={() => {setAddPromotionErrorMessage(''); handleAddPromotionMenuSave(); } }
                        variant='contained'
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Modal for Edit Promotion */}
            {/* <Dialog open={editPromotionMenuOpen} onClose={handleEditPromotionMenuClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Edit Promotion</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            This is where you can edit the promotion
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleEditPromotionMenuClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleEditPromotionMenuSave}
                        variant='contained'
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog> */}
            {/* Modal for Delete Promotion */}
            <Dialog open={deletePromotionMenuOpen} onClose={handleDeletePromotionMenuClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Delete Promotion</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            Are you sure you want to delete the selected promotion?
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleDeletePromotionMenuClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleDeletePromotion}
                        variant='contained'
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default Promotions;