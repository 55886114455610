import React, {useState} from "react";

import API from "../Generic/API";
import Formatter from "../Style/Formatter";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * TransactionTitleConfirmedAction component.
 *
 * @returns {*}
 * @constructor
 */
function TransactionTitleConfirmedAction(props) {
    const {record, onSuccess} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const open = Boolean(anchorEl);

    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Performs an update.
     *
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    const handlePropertyUpdate = async (key, value) => {
        setLoading(true);

        await API.send(
            `pay-at-close/transaction/${record.id}/parameter`,
            'POST',
            {
                key, value
            }
        );

        if (onSuccess) {
            onSuccess().then(() => setLoading(false));
        }
    };


    return (
        <div>
            <Button
                disabled={isLoading}
                className={'text__plain'}
                variant="link"
                disableElevation
                onClick={(event) => handleMenuOpen(event)}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                {Formatter.boolean(record.title_confirmed)}
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    handlePropertyUpdate('title_confirmed', true).then(() => true);
                }}>Yes</MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    handlePropertyUpdate('title_confirmed', false).then(() => true);
                }}>No</MenuItem>
            </Menu>
        </div>
    );
}

export default TransactionTitleConfirmedAction;