import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusLogIndicator from "../../Components/StatusLogIndicator";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

/**
 * StatusLog component.
 *
 * @returns {*}
 * @constructor
 */
function StatusLogTab(props) {
    const {record} = props;
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setLoading] = useState(false);

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        const getResults = async () => {
            setLoading(true);
            const response = await API.send(`pay-at-close/transaction/${record.id}/statuses`);
            setStatuses(response);
            setLoading(false);
        };

        getResults().then(() => true);
    }, [record]);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <div>
                        <h3 className={'text__bold'}>Status Log</h3>
                        <p className={'text__muted'}>
                            The following status updates are available for the transaction.
                        </p>
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table>
                    <colgroup>
                        <col width="40%"/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell align={'right'}><b>Details</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Skeleton width={'70%'} style={{marginLeft: 'auto'}}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !statuses.length && (
                            <TableRow>
                                <TableCell colSpan={2} align={'center'}>
                                    <div className={'text__muted'}>
                                        No statuses available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {statuses.map((status) => (
                            <TableRow>
                                <TableCell>
                                    <StatusLogIndicator status={status.status_text}/>

                                    {status.status_text}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {Formatter.date(status.dte, 'MM/DD/YYYY hh:mm A')} from <b>{status.originator === 'isnadmin' ? 'Control Panel' : 'ISN'}</b>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default StatusLogTab;