import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import AccountsTable from "./AccountsTable";

import { styled } from '@mui/material/styles';

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

/**
 * Results component.
 *
 * @returns {*}
 * @constructor
 */
function Results() {
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [updateLog, setUpdateLog] = useState('');
    const [uploadUpdate, setUploadUpdate] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFileSuccess, setUploadFileSuccess] = useState('');
    const [uploadFileInfo, setUploadFileInfo] = useState('');
    const [uploadFileError, setUploadFileError] = useState('');
    const [open, setOpen] = useState(false);
    const [updateInProcess, setUpdateInProcess] = useState(false);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleUploadFileChange = (file) => {
        setUploadFileError('');
        setUploadFileSuccess('');

        if (file.type !== 'text/csv') {
            setUploadFileError('The file format has to be .csv.');
            return;
        }

        setUploadFileSuccess('File successfully formatted.');

        setUploadFile(file);
    };

    const handleUploadOpen = async () => {
        setOpen(true);
        setUploadUpdate(false);
        setUploadFile(null);
    };

    const handleUploadClose = () => {
        setOpen(false);
        getResults();
    };

    /**
     * Loads all of the funding results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/accounts`);
        setRecords(response);
        setLoading(false);
    };

    /**
     * Sends the CSV file to the Guardian backend for processing
     */
    const updateGuardianFromCSV = async () => {
        setUploadFileSuccess('');

        if (uploadUpdate) {
            setUploadFileInfo('Parsing CSV and updating ISN and Guardian');
        } else {
            setUploadFileInfo('Parsing CSV and doing a trial run of the data');
        }

        setUpdateLog('');

        const username = localStorage.getItem('auth-username') || '';
        const formData = new FormData();
        formData.append('file', uploadFile);
        formData.append('fileName', uploadFile.name);

        const payload = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-Secret-Key': localStorage.getItem('auth-token') || '',
                'X-Access-Key': username,
            },
            body: formData,
        };

        const response = await API.fetch(API.url(`pay-at-close/accounts/upload/${uploadUpdate}/${username}`), payload);

        if (uploadUpdate) {
            setUpdateInProcess(true);

            // periodically poll the backend to get the status so we can relay it to the user
            const intervalID = setInterval(async () => {
                const response = await API.send(`pay-at-close/accounts/upload/status`);

                setUpdateLog(response.message);

                if (response.status === 'complete') {
                    clearInterval(intervalID);
                    setUploadFileSuccess('Account data update successfully finished. Log below.');
                }
            }, 30000);
            setUploadFileSuccess('CSV successfully parsed and update queued in the Guardian backend. Please stay on this page for updates.');
        } else {
            setUploadFileSuccess('CSV successfully parsed and trial run complete');
            setUpdateLog(response.message);
        }

        setUploadFileInfo('');
    };

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <div className={'block__heading block__heading--bordered'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Accounts {!isLoading ? `(${records.length})` : ''}</h3>
                            <p className={'text__muted'}>Your results are displayed below.</p>
                        </div>

                        <div>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                onClick={() => handleUploadOpen()}
                                sx={{marginTop:'7px', marginRight:'10px'}}
                                >
                                Update Accounts
                            </Button>
                            <TextField
                                label="Search..."
                                variant="outlined"
                                sx={{width: '280px'}}
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                            <IconButton
                                onClick={() => {
                                    document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                }}
                                style={{marginTop: '0.3em'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={open}
                    onClose={handleUploadClose}
                >
                    <DialogTitle>
                        <strong>Upload CSV File to update accounts in bulk</strong>
                    </DialogTitle>
                    <DialogContent>
                        <p>The expected format is an eight-columned CSV file.</p>
                        <p>
                            The columns are: companykey, current status, current enabled date, current disabled date, notes, status needs to be updated to, enabled date needs to be updated to, disabled date needs to be updated to.
                        </p>
                        <p>
                            Here is a <a href="https://porchdotcom365-my.sharepoint.com/:x:/r/personal/johnbranca_porch_com/Documents/Sample%20Guardian%20Accounts%20Page%20Upload.xlsx?d=w7125bdc6230642b4b80a5a959c1129ac&csf=1&web=1&e=fJxVfB" target="_blank">link</a> to a sample Excel file.
                        </p>
                        <p>
                            If you upload a file in an invalid format, it will not proceed. There is also a "Commit Update" checkbox, that by default is off, so you can see what the update is going to be before it's actually made. In order to update the data this checkbox needs to be checked.
                        </p>
                        <p>
                            Commit Update <Checkbox
                                checked={uploadUpdate}
                                onChange={(event) => setUploadUpdate(!!event.target.checked)}
                            />
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                >
                                Upload file
                                <VisuallyHiddenInput onChange={(event) => handleUploadFileChange(event.target.files[0])} type="file" />
                            </Button>
                            {uploadFileError !== '' ? <Alert severity="error">{uploadFileError}</Alert> : '' }
                            {uploadFileInfo !== '' ? <Alert severity="info">{uploadFileInfo}</Alert> : ''}
                            {uploadFileSuccess !== '' ? <Alert severity="success">{uploadFileSuccess}</Alert> : '' }
                        </p>
                        <p>
                            <Button
                                onClick={() => updateGuardianFromCSV()}
                                variant="contained"
                                disabled={uploadFile === null}
                            >Update</Button>
                        </p>
                        <span>
                            <span>Log of Changes</span><br/>
                            <pre>{updateLog}</pre>
                        </span>

                    </DialogContent>
                </Dialog>

                <AccountsTable
                    search={search}
                    records={records}
                    loading={isLoading}
                    onReload={getResults}
                />
            </Paper>
        </div>
    );
}

export default Results;
