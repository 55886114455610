import React, {useState} from "react";

import API from "../Generic/API";
import Statuses from "../Generic/Statuses";
import StatusLogIndicator from "./StatusLogIndicator";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * MultipleTransactionActions component.
 *
 * @returns {*}
 * @constructor
 */
function MultipleTransactionActions(props) {
    const {records, onReload, onClose, processStatuses} = props;

    let multipleRecords = JSON.parse(localStorage.getItem('multiple_transactions') || {});
    let record = null;
    const [status, setStatus] = useState(multipleRecords.length > 0 ? multipleRecords[0].status : "");
    const [processStatus, setProcessStatus] = useState(multipleRecords.length > 0 ? multipleRecords[0].funding_status : "");
    const [isLoading, setLoading] = useState(false);
    const [isStatusFormOpen, setStatusFormOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonText, setButtonText] = useState(processStatus || 'Not Started');
    const [processStatusValue, setProcessStatusValue] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);

    const open = Boolean(anchorEl);

    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Collapses the status menu.
     */
    const handleStatusMenuClose = () => {
        setStatusFormOpen(false);
        onClose();
    };

    /**
     * Updates the status of multiple transactions.
     */
    const doStatusSave = async () => {
        setLoading(true);
        if (statusChanged){
            await API.send(
                `pay-at-close/transaction/edit-multiple-status`,
                'POST',
                {
                    multipleRecords,
                    status
                }
            );
        }
        if (processStatusValue){
            await API.send(
                `pay-at-close/transaction/edit-multiple-process-status`,
                'POST',
                {
                    multipleRecords: multipleRecords,
                    status: processStatusValue
                }
            );
        }
        
        setLoading(false);
        setStatusFormOpen(false);

        if(onReload){
            window.location.reload(true);
        }
    };


    /**
     * Preupdates the process status of multiple transactions.
     */
    const handleStatusUpdate = async (value) => {
        setProcessStatusValue(value);
        setButtonText(value);
        setAnchorEl(false);
    };

    return (
        <div>
            <Dialog open={isStatusFormOpen} onClose={handleStatusMenuClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Update Status</h3>
                </DialogTitle>
                <DialogContent>
                    <div id="main_div">
                        <div className={'columns__1'}>
                            <div>
                                Please select a new status from the list below.
                                Note: The selected status will be the status for every transaction selected.
                            </div>
                            <Autocomplete
                                value={status}
                                options={Statuses.map((status) => {
                                    return {
                                        label: status
                                    };
                                })}
                                onChange={(event, value) => {
                                    setStatus(value.label);
                                    setStatusChanged(true);
                                }}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                        <StatusLogIndicator status={option.label}/>
                                        {option.label}
                                    </li>
                                )}
                                sx={{width: 450}}
                                renderInput={(params) => <TextField {...params} label="Status"/>}
                            />
                        </div>
                        <br/>
                        <h3 className={'text__bold'}>Update Process Status</h3>
                        <br/>
                        <div className={'columns__1'}>
                            <div>
                                Please select a new process status from the list below.
                                Note: The selected process status will be the status for every transaction selected.
                            </div>
                            <Button
                                id="process_status_id"
                                disabled={isLoading}
                                className={'text__plain process__button'}
                                variant="link"
                                disableElevation
                                onClick={(event) => handleMenuOpen(event)}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                {buttonText}
                            </Button>
                            <Menu
                                open={open}
                                anchorEl={anchorEl}
                            >
                                {processStatuses.map((value, index) => (
                                    <MenuItem key={`process-status-${index}`} onClick={() => {
                                        handleStatusUpdate(value).then(() => true);
                                    }}>{value}</MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusMenuClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={doStatusSave} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default MultipleTransactionActions;