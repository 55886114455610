import React, {Fragment, useState} from "react";

import API from "../../Generic/API";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * AccountActions component.
 *
 * @returns {*}
 * @constructor
 */
function AccountActions(props) {
    const {record, disabled, onReload} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const open = Boolean(anchorEl);

    /**
     * Saves an arbitrary parameter key.
     *
     * @returns {Promise<void>}
     */
    const doPropertySave = async (key, value) => {
        setLoading(true);

        await API.send(
            `pay-at-close/account/${record.name}/parameter`,
            'POST',
            {
                key, value
            }
        );

        // Reload the table results.
        if (onReload) {
            await onReload();
        }

        setLoading(false);
    };


    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Fragment>
                <IconButton onClick={handleMenuOpen} disabled={disabled || isLoading}>
                    <MoreHorizIcon/>
                </IconButton>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                >
                    {[
                        record.funding_status !== 'VERIFIED' ? <MenuItem key={`${record.id}_deliver`} onClick={async () => {
                            handleMenuClose();
                            await doPropertySave('funding_status', 'VERIFIED')
                        }}>Mark as "Verified"</MenuItem> : null,

                        record.funding_status === 'VERIFIED' ? <MenuItem key={`${record.id}_deliver`} onClick={async () => {
                            handleMenuClose();
                            await doPropertySave('funding_status', '')
                        }}>Revoke Verification</MenuItem> : null,
                    ]}
                </Menu>
            </Fragment>
        </div>
    );
}

export default AccountActions;