import React from "react";

import Results from "./Accounts/Results";

/**
 * Accounts component.
 *
 * @returns {*}
 * @constructor
 */
function Accounts() {
    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Account Management</h3>
            </div>

            <div className={'columns__1'}>
                <Results/>
            </div>
        </div>
    );
}

export default Accounts;
