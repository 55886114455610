import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";

/**
 * FundingBlock component.
 *
 * @returns {*}
 * @constructor
 */
function FundingBlock(props) {
    const {record} = props;
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(true);

    /**
     * Loads any results via the API.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/funding/${record.id}`);
        setRecords(response);
        setLoading(false);
    };


    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        if (!record.id) {
            return;
        }

        getResults().then(() => true);
    }, [record]);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'d-flex__justify'}>
                    <h3 className={'text__bold'}>Funding Activity</h3>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table stickyHeader>
                    <colgroup>
                        <col width={'28%'}/>
                        <col width={'28%'}/>
                        <col width={'28%'}/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Bounced</b></TableCell>
                            <TableCell><b>Amount</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !records.length && (
                            <TableRow>
                                <TableCell colSpan={4} align={'center'}>
                                    <div className={'text__muted'}>
                                        No activity available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {records.map((entry, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    {Formatter.dateTime(entry.date)}
                                </TableCell>
                                <TableCell>
                                    {entry.status}
                                </TableCell>
                                <TableCell>
                                    {entry.bounced ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell>
                                    {Formatter.currency(entry.amount)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default FundingBlock;