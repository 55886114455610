import React, {useState} from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Grouped component.
 *
 * @returns {*}
 * @constructor
 */
function Grouped(props) {
    const {eligible, search, records, isLoading} = props;

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {isLoading && <LinearProgress />}
            <MUIDataTable
                className={'table__grid inspection__count__and__revenue__report__table'}
                data={records}
                options={
                    {
                        searchText: search,
                        selectableRows: "none",
                        responsive: 'standard',
                        rowsPerPage: 100,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 260px)',
                        customRowRender: (data, i) => {
                            const record = records[i];
                            const currencyFormatter = Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });

                            return (
                                <TableRow key={`${eligible}-${record.companykey}`}>
                                    <TableCell className={'left__align'}>
                                        {record.companykey}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(record.pac_revenue)}
                                    </TableCell>
                                    <TableCell>
                                        {record.pac_inspections}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(record.non_pac_revenue)}
                                    </TableCell>
                                    <TableCell>
                                        {record.non_pac_inspections}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(record.total_revenue)}
                                    </TableCell>
                                    <TableCell>
                                        {record.total_inspections}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "companykey",
                        label: "Company Key",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "pac_revenue",
                        label: eligible + " FF Volume",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "pac_inspections",
                        label: eligible + " FF Inspections",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "non_pac_revenue",
                        label: eligible + " Non-FF Revenue",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "non_pac_inspections",
                        label: eligible + " Non-FF Inspections",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "total_revenue",
                        label: eligible + " Total Volume",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "total_inspections",
                        label: eligible + " Total Inspections",
                        options: {
                            sort: true,
                        }
                    },
                ]}
            />
        </TableContainer>
    );
}

export default Grouped;