import React from "react";

import Avatar from "@mui/material/Avatar";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

/**
 * ContactCard component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function ContactCard(props){
    const {record, property, initial, label} = props;
    const related = record[property];

    return related && related.id ? (
        <div className={'d-flex__justify'}>
            <Avatar className={'mr__2'}>{initial}</Avatar>
            <div className={'contact__content'}>
                <div className='d-flex__justify'>
                    <b>{related.display}</b>
                    <span className='text__muted'>{label}</span>
                </div>

                {related.email && (
                    <div className={'contact__link'}>
                        <a href={`mailto: ${related.email}`}>
                            <EmailIcon/> <span>{related.email}</span>
                        </a>
                    </div>
                )}

                {related.mobilePhone && (
                    <div className={'contact__link'}>
                        <a href={`tel: ${related.mobilePhone}`}>
                            <PhoneIcon/> <span>{related.mobilePhone}</span>
                        </a>
                    </div>
                )}

                {related.mobileNumber && (
                    <div className={'contact__link'}>
                        <a href={`tel: ${related.mobileNumber}`}>
                            <PhoneIcon/> <span>{related.mobileNumber}</span>
                        </a>
                    </div>
                )}
            </div>
        </div>
    ) : null;
}

export default ContactCard;