/**
 * API helper.
 *
 * @type {Object}
 */
const API = {
    /**
     * Returns an API endpoint based on the input.
     *
     * @param url
     * @returns {string}
     */
    url(url) {
        switch (process.env.REACT_APP_ENV) {
            case 'development':
                url = `https://guardian-api.localhost/${url}`;
                break;

            case 'staging':
                url = `https://api-staging.payatclose.net/${url}`;
                break;

            default:
                url = `https://api.payatclose.net/${url}`;
                break;
        }

        return url;
    },

    async fetch(url, payload) {
        const request = await fetch(url, payload);

        // Handle specific status codes.
        if (!request.ok) {
            switch (request.status) {
                case 401:
                    localStorage.removeItem('auth-user');
                    localStorage.removeItem('auth-token');
                    localStorage.removeItem('auth-username');

                    const location = window.location;
                    const loginUrl = `/login?url=${encodeURIComponent(location.pathname)}`;

                    // If we've been logged out at some point, direct the user to the login experience.
                    return window.location = loginUrl;
            }
        }

        return await request.json();
    },

    /**
     * Sends a request.
     *
     * @param url
     * @param method
     * @param data
     * @returns {*}
     */
    async send(url, method, data) {
        data = data || {};
        method = method || 'GET';

        // Utilize the appropriate environment target.
        url = API.url(url);

        try {
            const payload = {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Secret-Key': localStorage.getItem('auth-token') || '',
                    'X-Access-Key': localStorage.getItem('auth-username') || '',
                }
            };

            // Share data appropriately by method.
            switch (method) {
                case 'POST':
                    payload['body'] = JSON.stringify(data);
                    break;
            }

            return await this.fetch(url, payload);
        } catch (exception) {
            // Handle exceptions as necessary.
        }

        return {};
    }
};

export default API;