import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusChip from "../../Components/StatusChip";
import StatusLogIndicator from "../../Components/StatusLogIndicator";
import TransactionActions from "../../Components/TransactionActions";

import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import TransactionProcessStatusAction from "../../Components/TransactionProcessStatusAction";
import TransactionTitleConfirmedAction from "../../Components/TransactionTitleConfirmedAction";
import { Checkbox } from "@mui/material";

/**
 * TransactionsTableClient component.
 *
 * This is a slow version of the transactions table that is necessary because certain members
 * of the Guardian team want to be able to search addresses and other things that aren't
 * easy to do with our current infrastructure, so the data is downloaded to the client to do so
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function TransactionsTableClient(props) {
    const {searchText, loading, setLoading, filter, apiPrefixUrl, filterUpdate, setFilterUpdate, processStatuses} = props;
    const [records, setRecords] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [recordsSelected, setRecordsSelected] = useState([]);
    const [recordsObj, setRecordsObj] = useState([]);

    const hasFilterSelection = !!Object.keys(filter).filter(key => !!filter[key]).length;
    const isFilteringProcess = filter.pre_close || filter.not_started || filter.pre_collections || filter.collections || filter.invoice_sent || filter.payment_sent || filter.guardian_funded || filter.removed_from_pac;
    const isFilteringCollections = filter.cc_funded || filter.check_funded;
    const isFilteringTitleConfirmed = filter.tc_yes || filter.tc_no;
    const isFilteringQueuedForFunding = filter.funding_queued_yes || filter.funding_queued_no;

    if (document.getElementById("edit_multiple_transactions")) document.getElementById("edit_multiple_transactions").style.display = "none";

    // Filter process status.
    const filterProcessStatus = (record) => {
        if (!hasFilterSelection || (!isFilteringCollections && !isFilteringProcess)) {
            return true;
        }

        let matched = false;

        if(filter.pre_close){
            matched = record.funding_status === 'Pre-Close Title Confirmed'
        }

        if(!matched && filter.not_started){
            matched = !record.funding_status || record.funding_status === 'Not Started';
        }

        if(!matched && filter.pre_collections){
            matched = record.funding_status === 'Pre Collections';
        }

        if(!matched && (filter.collections || isFilteringCollections)){
            matched = record.funding_status === 'Collections';
        }

        if(!matched && filter.invoice_sent){
            matched = record.funding_status === 'Invoice Sent to Title';
        }

        if(!matched && filter.payment_sent){
            matched = record.funding_status === 'Payment Sent to Inspector';
        }

        if(!matched && filter.guardian_funded){
            matched = record.funding_status === 'Guardian Funded';
        }

        if(!matched && filter.removed_from_pac){
            matched = record.funding_status === 'Removed from PAC';
        }

        return matched;
    };

    // Filter title confirmed.
    const filterTitle = (record) => {
        if (!hasFilterSelection || !isFilteringTitleConfirmed) {
            return true;
        }

        let matched = false;

        if(filter.tc_yes){
            matched = !!record.title_confirmed;
        }

        if(!matched && filter.tc_no){
            matched = !(!!record.title_confirmed);
        }

        return matched;
    };

    // Filter collections.
    const filterCollections = (record) => {
        if (!hasFilterSelection || !isFilteringCollections) {
            return true;
        }

        let matched = false;

        if(filter.cc_funded){
            matched = record.collections_status === 'CC Funded';
        }

        if(!matched && filter.check_funded){
            matched = record.collections_status === 'Check Funded';
        }

        return matched;
    };

    // Filter queued for funding.
    const filterFunding = (record) => {
        if (!hasFilterSelection || !isFilteringQueuedForFunding) {
            return true;
        }

        let matched = false;

        if(filter.funding_queued_yes){
            matched = !!record.inspector_needs_paid;
        }

        if(!matched && filter.funding_queued_no){
            matched = !(!!record.inspector_needs_paid);
        }

        return matched;
    };

    const filterCompany = (record) => {
        if (!hasFilterSelection) {
            return true;
        }

        if (filter.company && filter.company.label) {
            return record.companykey === filter.company.label;
        }

        return true;
    };

    const getResults = async () => {
        setLoading(true);

        const response = await API.send(`${apiPrefixUrl}/client`);

        setRecords(response);
        filterResponse(response);

        setLoading(false);

        return response;
    };

    const filterResponse = (response) => {
        response = response.filter(record => {
            let matched = true;
            matched = matched && filterCompany(record);
            matched = matched && filterFunding(record);
            matched = matched && filterCollections(record);
            matched = matched && filterTitle(record);
            matched = matched && filterProcessStatus(record);

            return matched;
        });

        setFiltered(response);
    };

    /**
     *
     */
    const handleRowClick = (id, checked) => {
        if (checked){
            recordsSelected.push(id);
            recordsObj.push({"id": records[id].id, "status": records[id].status});
        }else{
            const index = recordsSelected.indexOf(id);
            if (index > -1) {
                recordsSelected.splice(index, 1);
                recordsObj.splice(index, 1);
            }
        }
        localStorage.setItem('multiple_transactions', JSON.stringify(recordsObj));
        if (recordsSelected.length > 1){ // only if 2 or more are selected
            document.getElementById("edit_multiple_transactions").style.display = "inline";
        }else{
            document.getElementById("edit_multiple_transactions").style.display = "none";
        }
    };

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        if (filterUpdate === true) {
            setLoading(true);
            setFilterUpdate(false);
            filterResponse(records);
            setLoading(false);
        } else if (filterUpdate === null) {
            setFilterUpdate(false);
            getResults().then(() => true);
        }
    }, [filter]);

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {loading && <LinearProgress />}
            <MUIDataTable
                className={'table__grid'}
                data={filtered.map(record => {
                    return {
                        id: `${record.id}`,
                        transaction: `${record.order_full_address}, ${record.order_number}, ${record.companykey}`,
                        client: record.client_name,
                        order_amount: record.amount,
                        inspector_amount: record.amount - (record.inspector_fee !== '' ? record.inspector_fee : 50),
                        process_status: record.funding_status || 'Not Started',
                        created_date: record.created_dte,
                        in_promotion: record.in_promotion,
                        inspection_date: record.order_datetime,
                        closing_date: record.closing_dte,
                        tc_ir: Formatter.boolean(record.title_confirmed),
                        status: record.status,
                        inspector_email: record.inspector_email,
                        actions: '',
                    };
                })}
                options={
                    {
                        searchText: searchText,
                        selectableRows: "none",
                        responsive: 'standard',
                        rowsPerPage: 100,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 230px)',
                        customRowRender: (data, i, rowIndex) => {
                            const record = filtered[i];

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <Checkbox
                                        onChange={(event) => handleRowClick(i, !!event.target.checked)}
                                        >
                                        </Checkbox>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/transaction/${record.id}`}>
                                            <StatusLogIndicator status={record.status}/>
                                            {record.order_full_address}
                                        </Link>
                                        <div>
                                            OID: {record.order_number}, CK: {record.companykey}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {record.client_name}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.currency(record.amount)}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.currency(record.amount - record.inspector_fee)}
                                    </TableCell>
                                    <TableCell>
                                        <TransactionProcessStatusAction
                                            record={record}
                                            onSuccess={getResults}
                                            processStatuses={processStatuses}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.created_dte)}
                                    </TableCell>
                                    <TableCell>
                                        {record.in_promotion}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.order_datetime)}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.closing_dte)}
                                    </TableCell>
                                    <TableCell>
                                        <TransactionTitleConfirmedAction
                                            record={record}
                                            onSuccess={getResults}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <StatusChip record={record}/>
                                    </TableCell>
                                    <TableCell>
                                        <TransactionActions record={record} onReload={getResults}/>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "selected",
                        label: " ",
                        options: {
                            sort: false,
                        }
                    },
                    {
                        name: "id",
                        label: "Id",
                        options: {
                            sort: false,
                            display: false,
                        }
                    }, {
                        name: "transaction",
                        label: "Transaction",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "client",
                        label: "Client",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "order_amount",
                        label: "Order Amount",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "inspector_amount",
                        label: "Inspector Amount",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "process_status",
                        label: "Process Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "created_date",
                        label: "Created Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "in_promotion",
                        label: "Created during Promo",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "inspection_date",
                        label: "Inspection Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "closing_date",
                        label: "Closing Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "tc_ir",
                        label: "TC / IR",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "status",
                        label: "Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "actions",
                        label: "Actions",
                        options: {
                            sort: true,
                        }
                    }
                ]}
            />
        </TableContainer>
    );
}

export default TransactionsTableClient;