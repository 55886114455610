import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Routes, Route} from "react-router-dom";
import {ThemeProvider} from "@emotion/react";

import './Style/App.css';
import Home from "./Pages/Home";
import Theme from "./Style/Theme";
import Login from "./Pages/Login";
import Charge from "./Pages/Charge";
import Payment from "./Pages/Payment";
import Account from "./Pages/Account";
import Funding from "./Pages/Funding";
import Accounts from "./Pages/Accounts";
import Reporting from "./Pages/Reporting";
import Navigation from "./Components/Navigation";
import Transaction from "./Pages/Transaction";
import PageNotFound from "./Pages/PageNotFound";
import Transactions from "./Pages/Transactions";
import Promotions from "./Pages/Reports/Promotions";
import {RequireAuth} from "./Providers/Authentication";
import TransactionNotes from "./Pages/Reports/TransactionNotes";
import BackupCreditCards from "./Pages/Reports/BackupCreditCards";
import InspectionCompanies from "./Pages/Reports/InspectionCompanies";
import InspectionCountAndRevenue from "./Pages/Reports/InspectionCountAndRevenue";

/**
 * Fallback for when an error occurs so we avoid a blank screen
 */
function fallbackRender({ error, resetErrorBoundary }) {
    return (
        <ThemeProvider theme={Theme}>
            <Navigation />
            <div role="alert" style={{ textAlign: "center" }}>
                <p>Something went wrong</p>
                <button onClick={resetErrorBoundary}>Try again</button>
            </div>
        </ThemeProvider>
    );
}

/**
 * App component.
 *
 * @returns {*}
 * @constructor
 */
function App() {
    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
        >
            <ThemeProvider theme={Theme}>
                <Navigation/>
                <div className={'page__wrapper'}>
                    <Routes>
                        <Route path="/" element={
                            <RequireAuth>
                                <Home/>
                            </RequireAuth>
                        }/>
                        <Route path="/funding" element={
                            <RequireAuth>
                                <Funding/>
                            </RequireAuth>
                        }/>
                        <Route path="/accounts" element={
                            <RequireAuth>
                                <Accounts/>
                            </RequireAuth>
                        }/>
                        <Route path="/account/:id" element={
                            <RequireAuth>
                                <Account defaultTab="overview" />
                            </RequireAuth>
                        } />
                        <Route path="/account/:id/services" element={
                            <RequireAuth>
                                <Account defaultTab="services" />
                            </RequireAuth>
                        } />
                        <Route path="/transactions" element={
                            <RequireAuth>
                                <Transactions/>
                            </RequireAuth>
                        }/>
                        <Route path="/reporting" element={
                            <RequireAuth>
                                <Reporting/>
                            </RequireAuth>
                        }/>
                        <Route path="/transaction/:id" element={
                            <RequireAuth>
                                <Transaction/>
                            </RequireAuth>
                        } />
                        <Route path="/reporting/backup-credit-cards" element={
                            <RequireAuth>
                                <BackupCreditCards/>
                            </RequireAuth>
                        } />
                        <Route path="/reporting/notes" element={
                            <RequireAuth>
                                <TransactionNotes/>
                            </RequireAuth>
                        } />
                        <Route path="/reporting/companies" element={
                            <RequireAuth>
                                <InspectionCompanies/>
                            </RequireAuth>
                        } />
                        <Route path="/reporting/revenue" element={
                            <RequireAuth>
                                <InspectionCountAndRevenue/>
                            </RequireAuth>
                        } />
                        <Route path="/reporting/promotions" element={
                            <RequireAuth>
                                <Promotions/>
                            </RequireAuth>
                        } />
                        <Route path="/transaction/:id/charge" element={
                            <RequireAuth>
                                <Charge/>
                            </RequireAuth>
                        } />
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/payment/:id" element={<Payment/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </div>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
