import React, {useState} from "react";

import API from "../Generic/API";
import Statuses from "../Generic/Statuses";
import StatusLogIndicator from "./StatusLogIndicator";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Alert } from "@mui/material";

/**
 * TransactionActions component.
 *
 * @returns {*}
 * @constructor
 */
function TransactionActions(props) {
    const {record, onReload} = props;

    const [status, setStatus] = useState(record.status);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isStatusFormOpen, setStatusFormOpen] = useState(false);
    const [isUploadAgreementFormOpen, setUploadAgreementFormOpen] = useState(false);
    const [agreement, setAgreement] = useState(null);
    const [agreementAlert, setAgreementAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const open = Boolean(anchorEl);

    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Expands the status menu.
     */
    const handleStatusMenuOpen = () => {
        setStatusFormOpen(true);
    };


    /**
     * Collapses the status menu.
     */
    const handleStatusMenuClose = () => {
        setStatusFormOpen(false);
    };

    /**
     * Expands the upload agreement menu.
     */
    const handleUploadAgreementMenuOpen = () => {
        setUploadAgreementFormOpen(true);
    };


    /**
     * Collapses the upload agreement menu.
     */
    const handleUploadAgreementMenuClose = () => {
        setUploadAgreementFormOpen(false);
        setAgreementAlert(false);
        setAgreement(null);
    };

    /**
     * Adds a file to be uploaded
     * 
     * @param {event} e 
     */
    const handleFileChange = (e) => {
        setAgreementAlert(false);
        if (e.target.files) {
            setAgreement(e.target.files[0]);
        }
    };

    /**
     * Uploads an agreement
     * 
     * @returns null
     */
    const handleUploadClick = async () => {
        setLoading(true);
        if (!agreement) {
            setLoading(false);
            setErrorMessage('Please choose a file to upload');
            setAgreementAlert(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', agreement)

        const res = await fetch(API.url(`pay-at-close/transaction/${record.id}/upload-agreement`), {
            method: 'POST',
            body: formData,
            headers: {
                'X-Secret-Key': localStorage.getItem('auth-token') || '',
                'X-Access-Key': localStorage.getItem('auth-username') || '',
            }
        })
        const response = await res.json()

        setLoading(false);

        if (response.status === 'error') {
            setErrorMessage(response.message);
            setAgreementAlert(true);
            return;
        }

        handleUploadAgreementMenuClose();

        // Reloads the page and performs another GET request 
        // to update the agreement
        window.location.href = window.location.href;
      };


    /**
     * Updates the status of a transaction.
     */
    const doStatusSave = async () => {
        setLoading(true);

        await API.send(
            `pay-at-close/transaction/${record.id}/status`,
            'POST',
            {
                status
            });

          if(status === 'FUNDED')
          {
            UpdateStatusBottom(status);
          }  

        setLoading(false);
        setStatusFormOpen(false);

        if(onReload){
            window.location.reload(true);
        }
    };

 const UpdateStatusBottom = async (value) => {
    await API.send(
        `pay-at-close/transaction/${record.id}/process-status`,
        'POST',
        {
            status: 'Guardian Funded'
        }
    )
   
    return true;
 };

    return (
        <div>
            <IconButton onClick={handleMenuOpen}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    handleStatusMenuOpen();
                }}>Update Status</MenuItem>

                <MenuItem onClick={() => {
                    handleMenuClose();
                    handleUploadAgreementMenuOpen();
                }}>Upload Agreement</MenuItem>

                <MenuItem onClick={() => {
                    handleMenuClose();
                    window.open(`https://inspectionsupport.com/${record.companykey}/order/view?oid=${record.order_number}`, '_blank');
                }}>View Inspection in ISN</MenuItem>
            </Menu>
            <Dialog open={isStatusFormOpen} onClose={handleStatusMenuClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Update Status</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            Please select a new status from the list below.
                        </div>
                        <Autocomplete
                            value={status}
                            options={Statuses.map((status) => {
                                return {
                                    label: status
                                };
                            })}
                            onChange={(event, value) => {
                                setStatus(value.label);
                            }}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <StatusLogIndicator status={option.label}/>
                                    {option.label}
                                </li>
                            )}
                            sx={{width: 450}}
                            renderInput={(params) => <TextField {...params} label="Status"/>}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusMenuClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={doStatusSave} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isUploadAgreementFormOpen} onClose={handleUploadAgreementMenuClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Upload Agreement</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            Please select an agreement to attach to this transaction.
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{ marginRight: '10px' }}
                            >
                                Choose File
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                            </Button>
                            {agreement ? agreement.name : 'No File Chosen'}
                        </div>
                        {agreementAlert &&
                        <div>
                            <Alert severity="error" hidden={!agreementAlert}>
                                {errorMessage ?? 'Failed to upload file'}
                            </Alert>
                        </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadAgreementMenuClose} disabled={isLoading}>Close</Button>
                    <Button onClick={handleUploadClick} disabled={isLoading} variant="contained" disableElevation >Upload</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TransactionActions;