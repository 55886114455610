import React, {Fragment, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import SaveIcon from '@mui/icons-material/Save';
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from "@mui/material/IconButton";
import {DesktopDatePicker} from "@mui/x-date-pickers";


/**
 * TitleCompanyBlock component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function TitleCompanyBlock(props) {
    const {record} = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [response, setResponse] = useState({});
    const [isMessageOpen, setMessageOpen] = useState(false);

    const [email, setEmail] = useState(record.title_company_email);
    const [phone, setPhone] = useState(record.title_company_phone);
    const [contact, setContact] = useState(record.title_company_contact);
    const [address, setAddress] = useState(record.title_company_address);
    const [closingDate, setClosingDate] = useState(record.closing_dte || null);
    const [companyName, setCompanyName] = useState(record.title_company_name);

    /**
     * Hides the note message.
     */
    const handleMessageClose = () => {
        setMessageOpen(false);
    };


    /**
     * Attempts to save the title company data.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setErrorMessage('');

        if (address === '') {
            setErrorMessage('Address cannot be empty.');
            return;
        }

        setLoading(true);

        const response = await API.send(
            `pay-at-close/transaction/${record.id}/title-company`,
            'POST',
            {
                tc_name: companyName,
                tc_email: email,
                tc_phone: phone,
                tc_contact: contact,
                tc_address: address,
                closing_date: closingDate
            }
        );

        setMessageOpen(true);
        setResponse(response);
        setLoading(false);

        // Handle error responses.
        if (response.status && response.status === 'error') {
            return;
        }

        setEditing(false);
    };

    return (
        <Paper>
            <div className={'columns__1'}>
                <div className={'d-flex__justify'}>
                    <div>
                        <h3 className={'text__bold'}>Title Company</h3>
                        <p className={'text__muted'}>
                            Any information related to the title company for this inspection is displayed ahead.
                        </p>
                    </div>

                    <div className={'transaction__actions'}>
                        {isEditing ? (
                            <Fragment>
                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => doSave()}
                                >
                                    <SaveIcon/>
                                </IconButton>

                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => setEditing(false)}
                                    color={'error'}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Fragment>
                        ) : (
                            <IconButton onClick={() => setEditing(true)}>
                                <CreateIcon/>
                            </IconButton>
                        )}
                    </div>

                </div>
                <Divider/>
                {errorMessage !== '' ?
                <Alert severity="error">{errorMessage}</Alert>
                : ''}
                <div className={'columns__3'}>
                    {isEditing ? (
                        <Fragment>
                            <DesktopDatePicker
                                label="Closing Date"
                                inputFormat="MM/DD/YYYY"
                                value={closingDate}
                                disabled={isLoading}
                                onChange={(value) => setClosingDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <TextField
                                label="Title Company Name"
                                variant="outlined"
                                value={companyName}
                                disabled={isLoading}
                                onChange={(event) => setCompanyName(event.target.value)}
                            />

                            <TextField
                                label="Title Company Contact"
                                variant="outlined"
                                value={contact}
                                disabled={isLoading}
                                onChange={(event) => setContact(event.target.value)}
                            />

                            <TextField
                                label="Title Company Email"
                                variant="outlined"
                                value={email}
                                disabled={isLoading}
                                onChange={(event) => setEmail(event.target.value)}
                            />

                            <TextField
                                label="Title Company Phone"
                                variant="outlined"
                                value={phone}
                                disabled={isLoading}
                                onChange={(event) => setPhone(event.target.value)}
                            />

                            <TextField
                                label="Title Company Address"
                                variant="outlined"
                                value={address}
                                disabled={isLoading}
                                onChange={(event) => setAddress(event.target.value)}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className={'detail__field'}>
                                <b>Closing Date</b>
                                <div>{Formatter.date(closingDate)}</div>
                            </div>

                            <div className={'detail__field'}>
                                <b>Title Company Name</b>
                                <div>{companyName}</div>
                            </div>

                            <div className={'detail__field'}>
                                <b>Title Company Contact</b>
                                <div>{contact}</div>
                            </div>

                            <div className={'detail__field'}>
                                <b>Title Company Email</b>
                                <div>{Formatter.email(email)}</div>
                            </div>

                            <div className={'detail__field'}>
                                <b>Title Company Phone</b>
                                <div>{Formatter.phone(phone)}</div>
                            </div>

                            <div className={'detail__field'}>
                                <b>Title Company Address</b>
                                <div>{address}</div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

            <Snackbar
                open={isMessageOpen}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleMessageClose}
            >
                <Alert onClose={handleMessageClose} severity={response.status} sx={{width: '100%'}}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

export default TitleCompanyBlock;