import React from "react";

/**
 * PageNotFound component.
 *
 * @returns {*}
 * @constructor
 */
function PageNotFound() {
    return (
        <div className={'columns__1'}>
            <h3 className={'not__found'}>
                The page you are trying to access could not be found.
            </h3>
        </div>
    );
}

export default PageNotFound;