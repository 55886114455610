import React from "react";

import RecentAlerts from "./Home/RecentAlerts";
import UpcomingClosings from "./Home/UpcomingClosings";

/**
 * Home component.
 *
 * @returns {*}
 * @constructor
 */
function Home() {
    // Determine the user's first name.
    const user = JSON.parse(localStorage.getItem('auth-user'));
    const name = user && user.full_name ? user.full_name : '';
    const first = name.split(/(\s+)/)[0];

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify'}>
                {name ?
                    <h3>Welcome back, {first}!</h3> :
                    <h3>Welcome back!</h3>
                }
            </div>
            <div className={'columns__2'}>
                <RecentAlerts/>
                <UpcomingClosings/>
            </div>
        </div>
    );
}

export default Home;
