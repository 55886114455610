import React, {useEffect, useState} from "react";

import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import MultipleTransactionActions from "../../Components/MultipleTransactionActions";

/**
 * TransactionsUI component.
 *
 * @returns {*}
 * @constructor
 */
function SearchClient(props) {
    const {search, setSearchText, drawerWidth, processStatuses} = props;

    const [editingMultipleTransactions, setEditingMultipleTransactions] = useState(false);

    const handleEditingMultipleTransactionDialogOpen = () => {
        setEditingMultipleTransactions(true);
    };

    const handleEditingMultipleTransactionDialogClose = () => {
        setEditingMultipleTransactions(false);
    };

    return (
        <div style={{display : "flex"}}>
            {editingMultipleTransactions && <MultipleTransactionActions records={[]} onReload={true} onClose={handleEditingMultipleTransactionDialogClose} processStatuses={processStatuses}/>}
            <Button id="edit_multiple_transactions" variant="text" style={{ display : "none"}} onClick={handleEditingMultipleTransactionDialogOpen}>Edit Multiple Transactions</Button>
            <TextField
                label="Search..."
                variant="outlined"
                sx={{width: drawerWidth}}
                value={search}
                onChange={(event) => {
                    const value = event.target.value;

                    setSearchText(value);
                    localStorage.setItem('transactions__search', value);
                }}
            />
            <IconButton
                onClick={() => {
                    document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                }}
                style={{marginTop: '0.3em'}}
            >
                <DownloadIcon/>
            </IconButton>
        </div>
    );
}

export default SearchClient;