/**
 * All available statuses.
 *
 * @type {Array}
 */
const Statuses = [
    // "In Progress" statuses.
    'ORDER_SUBMITTED',
    'SIGNATURE_RECEIVED',
    'CLOSING_COMPLETE',
    'REJECT_NON_PAYMENT_TE',
    'REJECT_NON_PAYMENT_CLIENT',

    // "Complete" statuses.
    'FUNDED',
    'TE_PAYMENT_COMPLETE',
    'CLIENT_PAYMENT_COMPLETE',

    // "Error" statuses.
    'CANCEL_NO_SIGNATURE_RECEIVED',
    'ORDER_CANCELED',
    'ORDER_DELETED',
    'CLOSING_FELL_THROUGH',
    'CANCELED_PAID_DIRECTLY_TO_INSPECTOR'
];

export default Statuses;