import React, {Fragment, useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import ContactCard from "./ContactCard";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

/**
 * IntegrationTab component.
 *
 * @returns {*}
 * @constructor
 */
function IntegrationTab(props) {
    const {record} = props;
    const [emails, setEmails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState([]);
    const [isLoadingEmails, setLoadingEmails] = useState(false);

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        if (isLoading) {
            return;
        }

        // Loads any results via the API.
        const getResults = async () => {
            setLoading(true);
            setLoadingEmails(true);

            const response = await API.send(`pay-at-close/transaction/${record.id}/integration`);
            setTransaction(response);
            setLoading(false);

            const emails = await API.send(`pay-at-close/transaction/${record.id}/emails`);
            setEmails(emails);
            setLoadingEmails(false);
        };

        getResults().then(() => true);
    }, [record]);

    return !record.valid ? (
        <div className={'columns__1'}>
            <Paper>
                <div className={'text__center text__muted'}>
                    Unable to capture inspection details.
                </div>
            </Paper>
        </div>
    ) : (
        <div className={'columns__2_3'}>
            <div className={'columns__1'}>
                <Paper>
                    <div className={'columns__1'}>
                        <div>
                            <h3 className={'text__bold'}>Inspection Details</h3>
                            <p className={'text__muted'}>
                                The following details are available for the selected transaction.
                            </p>
                        </div>

                        <Divider/>

                        {isLoading ? (
                            <Fragment>
                                <div className={'columns__3'}>
                                    {Array.from(Array(9).keys()).map((i, j) => {
                                        return (
                                            <div className={'detail__field'} key={j}>
                                                <Skeleton
                                                    width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                    height={'1.3em'}
                                                />
                                                <Skeleton
                                                    width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                    height={'1.3em'}
                                                    style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={'columns__3'}>
                                    <div className={'detail__field'}>
                                        <b>Order ID</b>
                                        <div>
                                            OID: #{transaction.orderId}
                                        </div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Total</b>
                                        <div>
                                            {Formatter.currency(transaction.grandTotal)}
                                        </div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Inspection Type</b>
                                        <div>
                                            {transaction.orderType && transaction.orderType.name ? transaction.orderType.name : ''}
                                        </div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Date</b>
                                        <div>{Formatter.date(transaction.date)}</div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Created Date</b>
                                        <div>{Formatter.date(transaction.createdDate)}</div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Signed Date</b>
                                        <div>{Formatter.date(transaction.signedDate)}</div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Closing Date</b>
                                        <div>{Formatter.date(transaction.closingDate)}</div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Scheduled Date</b>
                                        <div>{Formatter.date(transaction.scheduledDate)}</div>
                                    </div>

                                    <div className={'detail__field'}>
                                        <b>Is Online Scheduler</b>
                                        <div>{Formatter.boolean(transaction.isOnlineScheduler)}</div>
                                    </div>
                                </div>

                                <TableContainer component={'div'}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Service</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!transaction.orderServices || !transaction.orderServices.length ? (
                                                <TableRow>
                                                    <TableCell align={'center'}>
                                                        <div className={'text__muted'}>
                                                            No services available.
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                transaction.orderServices.map((orderService) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>
                                                                {orderService && orderService.service ? orderService.service.name : ''}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        )}
                    </div>
                </Paper>

                {transaction.id && (
                    <Paper>
                        <div className={'columns__1'}>
                            <div>
                                <h3 className={'text__bold'}>Property Details</h3>
                            </div>

                            <div className={'columns__3'}>
                                <div className={'detail__field'}>
                                    <b>Address Line 1</b>
                                    <div>{transaction.addressLine1}</div>
                                </div>
                                <div className={'detail__field'}>
                                    <b>Address Line 2</b>
                                    <div>{transaction.addressLine2}</div>
                                </div>
                                <div className={'detail__field'}>
                                    <b>City</b>
                                    <div>{transaction.city}</div>
                                </div>
                                <div className={'detail__field'}>
                                    <b>State</b>
                                    <div>{transaction.state ? transaction.state.name : ''}</div>
                                </div>
                                <div className={'detail__field'}>
                                    <b>Zip</b>
                                    <div>{transaction.zip}</div>
                                </div>
                                <div className={'detail__field'}>
                                    <b>County</b>
                                    <div>{transaction.county}</div>
                                </div>
                            </div>

                            <TableContainer component={'div'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Label</b></TableCell>
                                            <TableCell><b>Value</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!transaction.controls || !transaction.controls.length ? (
                                            <TableRow>
                                                <TableCell colSpan={2} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No controls available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            transaction.controls.map((control) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {control.label}
                                                        </TableCell>
                                                        <TableCell>
                                                            {control.value}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}

                {transaction.id && (
                    <Paper className={'table__block'}>
                        <div className={'block__heading'}>
                            <h3 className={'text__bold'}>Email History</h3>
                        </div>
                        <div className={'columns__1'}>
                            <TableContainer component={'div'} className={'list__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Date</b></TableCell>
                                            <TableCell><b>Sender</b></TableCell>
                                            <TableCell><b>Recipient</b></TableCell>
                                            <TableCell><b>Subject</b></TableCell>
                                            <TableCell><b>Excerpt</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoadingEmails ? (
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton/>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton/>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton/>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton/>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton/>
                                                </TableCell>
                                            </TableRow>
                                        ) : !emails || !emails.length ? (
                                            <TableRow>
                                                <TableCell colSpan={5} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No email events available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            emails.map((email) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {Formatter.dateTime(email.dateTime)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.fromName} &lt;{email.fromEmail}&gt;
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.toName} &lt;{email.toEmail}&gt;
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.subject}
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.excerpt}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}

                {transaction.id && (
                    <Paper className={'table__block'}>
                        <div className={'block__heading'}>
                            <h3 className={'text__bold'}>Email Events</h3>
                        </div>
                        <div className={'columns__1'}>
                            <TableContainer component={'div'} className={'list__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Name</b></TableCell>
                                            <TableCell><b>Date</b></TableCell>
                                            <TableCell><b>Sent Date</b></TableCell>
                                            <TableCell><b>Recipient</b></TableCell>
                                            <TableCell><b>Sent</b></TableCell>
                                            <TableCell><b>Bounced</b></TableCell>
                                            <TableCell><b>Read</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!transaction.emailEventQueue || !transaction.emailEventQueue.length ? (
                                            <TableRow>
                                                <TableCell colSpan={7} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No email events available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            transaction.emailEventQueue.map((queue) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {queue['email-event-schema'] && queue['email-event-schema'].name ? queue['email-event-schema'].name : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.dateTime(queue.date)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.dateTime(queue.sentDate)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {queue.sentToName}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.boolean(queue.isSent)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.boolean(queue.isBounce)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.boolean(queue.isRead)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}

                {transaction.id && (
                    <Paper className={'table__block'}>
                        <div className={'block__heading'}>
                            <h3 className={'text__bold'}>Fee History</h3>
                        </div>
                        <div className={'columns__1'}>
                            <TableContainer component={'div'} className={'list__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Date</b></TableCell>
                                            <TableCell align={'right'}><b>Total</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!transaction.feeHistory || !transaction.feeHistory.length ? (
                                            <TableRow>
                                                <TableCell colSpan={2} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No fee history available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            transaction.feeHistory.map((history) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {Formatter.dateTime(history.date)}
                                                        </TableCell>
                                                        <TableCell align={'right'}>
                                                            {Formatter.currency(history.fee)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}

                {transaction.id && (
                    <Paper className={'table__block'}>
                        <div className={'block__heading'}>
                            <h3 className={'text__bold'}>Inspection Notes</h3>
                        </div>
                        <div className={'columns__1'}>
                            <TableContainer component={'div'} className={'list__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Date</b></TableCell>
                                            <TableCell><b>Note</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!transaction.notes || !transaction.notes.length ? (
                                            <TableRow>
                                                <TableCell colSpan={2} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No notes available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            transaction.notes.map((notes) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {Formatter.dateTime(notes.date)}
                                                        </TableCell>
                                                        <TableCell dangerouslySetInnerHTML={{__html: notes.text}}/>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}
            </div>

            <div className={'columns__1'}>
                <Paper>
                    <div className={'columns__1 gap__4'}>
                        <div>
                            <h3 className={'text__bold'}>Contacts</h3>
                        </div>

                        {isLoading && (
                            <div className={'d-flex__justify'}>
                                <div className={'mr__2'}>
                                    <Skeleton variant={'circular'} height={'3em'} width={'3em'}/>
                                </div>
                                <div className={'columns__1'}>
                                    <div className={'d-flex__justify'}>
                                        <Skeleton width={'30%'}/>
                                        <Skeleton width={'30%'}/>
                                    </div>
                                    <Skeleton width={'60%'}/>
                                    <Skeleton width={'40%'}/>
                                </div>
                            </div>
                        )}

                        <ContactCard
                            record={transaction}
                            initial={'C'}
                            label={'Client'}
                            property={'client'}
                        />
                        <ContactCard
                            record={transaction}
                            initial={'PI'}
                            label={'Primary Inspector'}
                            property={'inspector1'}
                        />
                        <ContactCard
                            record={transaction}
                            initial={'SI'}
                            label={'Secondary Inspector'}
                            property={'inspector2'}
                        />
                        <ContactCard
                            record={transaction}
                            initial={'BA'}
                            label={'Buyer\'s Agent'}
                            property={'buyersAgent'}
                        />
                        <ContactCard
                            record={transaction}
                            initial={'SA'}
                            label={'Seller\'s Agent'}
                            property={'sellersAgent'}
                        />
                    </div>
                </Paper>

                {transaction.id && (
                    <Paper className={'table__block'}>
                        <div className={'block__heading'}>
                            <h3 className={'text__bold'}>Inspection Fees</h3>
                        </div>
                        <div className={'columns__1'}>
                            <TableContainer component={'div'} className={'list__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Fee</b></TableCell>
                                            <TableCell><b>Value</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!transaction.orderFees || !transaction.orderFees.length ? (
                                            <TableRow>
                                                <TableCell colSpan={2} align={'center'}>
                                                    <div className={'text__muted'}>
                                                        No fees available.
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            transaction.orderFees.map((fee) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {fee.fees && fee.fees.name ? fee.fees.name : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Formatter.currency(fee.value)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                )}

                {transaction.id && (
                    <Paper>
                        <div>
                            <h3 className={'text__bold'}>Invoice</h3>
                        </div>

                        <div className={'columns__1'}>
                            <iframe src={transaction.invoiceUrlHtml.match(/href="([^"]*)/)[1]} width="100%"
                                    height="500px" frameBorder="0"/>
                        </div>
                    </Paper>
                )}
            </div>
        </div>
    );
}

export default IntegrationTab;