import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import DownloadCsv from "../../Generic/DownloadCsv";
import Formatter from "../../Style/Formatter";
import PaymentsTable from "./PaymentsTable";
import TabContainer from "../../Components/TabContainer";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TableRow from "@mui/material/TableRow";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import MUIDataTable from "mui-datatables";
import DownloadIcon from '@mui/icons-material/Download';
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";

/**
 * Batches component.
 *
 * @returns {*}
 * @constructor
 */
function Batches() {
    const [search, setSearch] = useState('');
    const [batchRecords, setBatchRecords] = useState([]);
    const [recordsLength, setRecordsLength] = useState(0);
    const [selected, setSelected] = useState({});
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isBatchLoading, setBatchLoading] = useState(false);
    const [isLogLoading, setLogLoading] = useState(false);
    const [areLogsLoading, setLogsLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [logText, setLogText] = useState('');
    const [creditDebitText, setCreditDebitText] = useState('');
    const [logDate, setLogDate] = useState(null);
    const [batchRecordsLength, setBatchRecordsLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [currentSortOrder, setSortOrder] = useState({ name: null, direction: null});
    const [tab, setTab] = useState('');

    /**
     * Organizes parameters to send to the API
     *
     * @returns {Object}
     */
    const getParametersForUrl = (page = 0, rows = 100, sortOrder = {name: null, direction: null}, download = false) => {
        setCurrentPage(page);
        setRowsPerPage(rows);
        setSortOrder(sortOrder);

        const encodedSearch = search == '' ? null : encodeURIComponent(search);
        const start = page * rows;

        return {
            start: start,
            offset: rows,
            sortOrder: sortOrder,
            search: search,
            download: download,
        };
    };

    /**
     * Loads all of the funding results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async (page, rows, sortOrder) => {
        setBatchLoading(true);

        const parameters = getParametersForUrl(page, rows, sortOrder);
        const response = await API.send("pay-at-close/funding/batches/search/" + JSON.stringify(parameters));

        setBatchRecords(response.records);
        setBatchRecordsLength(response.count);
        setBatchLoading(false);
    };

    /**
     * Changes the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = async (event, updated) => {
        setTab(updated);
        setCreditDebitText('');

        setLogLoading(true);

        const encodedFile = encodeURIComponent(updated);
        const response = await API.send(`pay-at-close/logs/funding/name/${encodedFile}`);

        if (response.body.length > 0) {
            let body = response.body;
            if (response.isDebit) {
                setCreditDebitText("THESE ARE DEBIT TRANSACTIONS");
            } else if (response.isCredit) {
                setCreditDebitText("THESE ARE CREDIT TRANSACTIONS");
            }
            setLogText(response.body);
        } else {
            setLogText('There was a problem fetching that log. Try again later.');
        }

        setLogLoading(false);
    };

    const handleBatchOpen = (batch) => {
        setSelected(batch);
    };

    const handleBatchClose = () => {
        setSelected({});
    };

    const handleLogOpen = async (batch) => {
        setOpen(true);
        setLogDate(batch.date);
        setLogsLoading(true);
        const batchDateEncoded = batch.date.replace(/\//g, '-');

        const response = await API.send(`pay-at-close/logs/funding/${batchDateEncoded}`);

        if (Array.isArray(response)) {
            setLogs(response);
            handleTabChange({}, response[0].path);
        } else {
            setLogs([]);
        }

        setLogsLoading(false);
    };

    const handleLogClose = () => {
        setOpen(false);
        setLogDate(null);
        setLogs([]);
    };

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <div className={'block__heading block__heading--bordered'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Batches {!isLoading ? `(${batchRecordsLength})` : ''}</h3>
                            <p className={'text__muted'}>Your results are displayed below.</p>
                        </div>

                        <div>
                            <TextField
                                label="Search..."
                                variant="outlined"
                                sx={{width: '280px'}}
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={open}
                    onClose={handleLogClose}
                >
                    <DialogTitle>
                        Logs for {logDate}
                    </DialogTitle>
                    <DialogContent>
                        {areLogsLoading && <LinearProgress/>}
                        {logs.length > 0 ?
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <div className={'d-flex__justify'}>
                                <Tabs value={tab} onChange={handleTabChange}>
                            {logs.map((value, index) => <Tab key={'tab-' + index} label={value.name} value={value.path} />)}
                                </Tabs>
                            </div>
                        </Box>
                        : areLogsLoading === false ?
                            <DialogContentText>There are no logs for that date.</DialogContentText> : ''
                        }
                        {logs.length > 0 ?
                        <div>
                            {logs.map((value, index) =>
                            <TabContainer key={'tab-container-' + index} value={tab} target={value.path}>
                                {isLogLoading ? '' : <p style={{ fontWeight: 'bold' }}>{creditDebitText}</p>}
                                <pre>{isLogLoading ? '' : logText}</pre>
                                {isLogLoading && <LinearProgress/>}
                            </TabContainer>
                            )}
                        </div>
                        : ''}
                    </DialogContent>
                </Dialog>

                <TableContainer component={'div'} className={'transactions__list'}>
                    {isBatchLoading && <LinearProgress/>}
                    <MUIDataTable
                        data={batchRecords}
                        className={'table__grid'}
                        options={
                            {
                                searchText: search,
                                selectableRows: "none",
                                responsive: 'standard',
                                count: batchRecordsLength,
                                rowsPerPage: rowsPerPage,
                                rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                filter: false,
                                search: false,
                                print: false,
                                download: false,
                                viewColumns: true,
                                customToolbar: null,
                                fixedHeader: true,
                                tableBodyHeight: 'calc(100vh - 300px)',
                                serverSide: true,
                                onTableChange: (action, tableState) => {
                                    if (['changePage', 'changeRowsPerPage', 'sort'].indexOf(action) > -1) {
                                        getResults(tableState.page, tableState.rowsPerPage, tableState.sortOrder).then(() => true);

                                        // scroll to the top of the table since results have been loaded in
                                        const table = document.querySelector('table');
                                        table.scrollIntoView(true);
                                    }
                                },
                                customRowRender: (data, i) => {
                                    const batch = batchRecords[i];
                                    const dateRegex = /([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g;
                                    const regexResults = dateRegex.exec(batch.date);
                                    const batchDateFormatted = `${regexResults[3]}-${regexResults[1]}-${regexResults[2]}`;

                                    return (
                                        <TableRow key={i}>
                                            <TableCell width={'33%'}>
                                                <Button onClick={() => handleBatchOpen(batch)}>
                                                    {batch.date}
                                                </Button>

                                                <Dialog
                                                    fullWidth={true}
                                                    maxWidth={'lg'}
                                                    open={selected.date === batch.date}
                                                    onClose={handleBatchClose}
                                                >
                                                    <DialogTitle>
                                                        Payments for {batch.date}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Please review the following transactions for this batch.
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <PaymentsTable
                                                        condensed
                                                        search={batchDateFormatted}
                                                        isLoading={isLoading}
                                                        setLoading={setLoading}
                                                        recordsLength={recordsLength}
                                                        setRecordsLength={setRecordsLength}
                                                    />
                                                </Dialog>
                                            </TableCell>
                                            <TableCell width={'33%'}>
                                                {Formatter.currency(batch.credit)}
                                            </TableCell>
                                            <TableCell width={'33%'}>
                                                {Formatter.currency(batch.debit)}
                                            </TableCell>
                                            <TableCell>
                                                {batch.logs === 'yes' && (batch.credit > 0 || batch.debit < 0) ?
                                                <Button onClick={() => handleLogOpen(batch)}>
                                                    Logs
                                                </Button> :
                                                ''
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            }
                        }
                        columns={[
                            {
                                name: "date",
                                label: "Batch Date",
                                options: {
                                    sort: true,
                                }
                            }, {
                                name: "credit",
                                label: "Credit",
                                options: {
                                    sort: true,
                                }
                            }, {
                                name: "debit",
                                label: "Debit",
                                options: {
                                    sort: true,
                                }
                            }, {
                                name: "logs",
                                label: "Logs",
                                options: {
                                    sort: false
                                }
                            }
                        ]}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Batches;
