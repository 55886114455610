import React, {useState} from "react";

import Active from "./Promotions/Active";
import History from "./Promotions/History";

import TabContainer from "../../Components/TabContainer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

/**
 * Promotions component.
 *
 * @returns {*}
 * @constructor
 */
function Promotions() {
    const [tab, setTab] = useState('active');

    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setTab(updated);
    };

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Promotion Activity</h3>

                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Active" value="active"/>
                    <Tab label="History" value="history"/>
                </Tabs>
            </div>

            <TabContainer value={tab} target={'active'}>
                <div className={'columns__1'}>
                    <Active/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'history'}>
                <div className={'columns__1'}>
                    <History/>
                </div>
            </TabContainer>
        </div>
    );
}

export default Promotions;
