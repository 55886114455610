import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusLogIndicator from "../../Components/StatusLogIndicator";


import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import DialogContentText from "@mui/material/DialogContentText";

/**
 * Returns the appropriate record alert message.
 *
 * @param props
 * @returns {string}
 */
const AlertMessage = (props) => {
    const {record} = props;

    if (record.fees_updated) {
        return <div>This inspection has updated fees.</div>
    } else if (record.payment_rejected) {
        return <div>The backup payment method was rejected.</div>
    } else {
        return <div/>;
    }
};


/**
 * RecentAlerts component.
 *
 * @returns {*}
 * @constructor
 */
function RecentAlerts() {
    const [isLoading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [target, setTarget] = useState({});
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    /**
     * Loads all records.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send('pay-at-close/alerts');
        setRecords(response);
        setLoading(false);
    };


    /**
     * Opens the confirmation dialogue.
     *
     * @param target
     */
    const handleConfirmationOpen = (target) => {
        setTarget(target);
        setConfirmationOpen(true);
    };


    /**
     * Closes the confirmation dialogue.
     */
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };


    /**
     * Handles the resolve action against the target record.
     *
     * @returns {Promise<void>}
     */
    const doResolve = async () => {
        setRecords([]);
        setLoading(true);

        const endpoint = target.fees_updated ? 'resolve-fee' : (target.payment_rejected ? 'resolve-payment' : 'not-found');
        await API.send(`pay-at-close/transaction/${target.id}/${endpoint}`, 'POST');

        setConfirmationOpen(false);
        getResults();
    };


    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <h3 className={'text__bold'}>Recent Alerts</h3>
                    <div className={'text__muted'}>
                        The following transactions are currently in an error state and require attention.
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table stickyHeader>
                    <colgroup>
                        <col width={'25%'}/>
                        <col/>
                        <col width={'25%'}/>
                        <col width={'20%'}/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Description</b></TableCell>
                            <TableCell><b>Message</b></TableCell>
                            <TableCell><b>Closing Date</b></TableCell>
                            <TableCell align={'right'}><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton/>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton variant={'rounded'} height={'2.5em'}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !Object.keys(records).length && (
                            <TableRow>
                                <TableCell colSpan={4} align={'center'}>
                                    <div className={'text__muted'}>
                                        No results available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {Object.keys(records).map((id, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <Link to={`/transaction/${id}`}>
                                        <StatusLogIndicator status={records[id].status}/>
                                        {records[id].order_full_address}
                                    </Link>
                                    <div>
                                        OID: {records[id].order_number}, CK: {records[id].companykey}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <AlertMessage record={records[id]}/>
                                </TableCell>
                                <TableCell>
                                    {Formatter.date(records[id].closing_dte)}
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Button variant={'contained'}
                                            onClick={() => handleConfirmationOpen(records[id])}>Resolve</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={isConfirmationOpen}
                onClose={handleConfirmationClose}
            >
                <DialogTitle>
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you would like to mark this as resolved? This will remove the transaction from your
                        alert window.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose} disabled={isLoading}>No</Button>
                    <Button onClick={doResolve} autoFocus disabled={isLoading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default RecentAlerts;
