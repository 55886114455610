import React, {useState} from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Individual component.
 *
 * @returns {*}
 * @constructor
 */
function Individual(props) {
    const {search, isLoading, records} = props;

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {isLoading && <LinearProgress />}
            <MUIDataTable
                className={'table__grid inspection__count__and__revenue__report__table'}
                data={records}
                options={
                    {
                        searchText: search,
                        selectableRows: "none",
                        responsive: 'standard',
                        rowsPerPage: 100,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 260px)',
                        customRowRender: (data, i) => {
                            const record = records[i];
                            const currencyFormatter = Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });

                            return (
                                <TableRow key={`${record.companykey}-${record.order_id}`}>
                                    <TableCell className={'left__align'}>
                                        {record.companykey}
                                    </TableCell>
                                    <TableCell>
                                        {record.order_id}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(record.fee_total)}
                                    </TableCell>
                                    <TableCell>
                                        {record.inspection_date}
                                    </TableCell>
                                    <TableCell>
                                        {record.is_payment_received === 1 ? 'yes' : 'no'}
                                    </TableCell>
                                    <TableCell>
                                        {record.is_pac === 1 ? 'yes' : 'no'}
                                    </TableCell>
                                    <TableCell>
                                        {record.is_pac_eligible === 1 ? 'yes' : 'no'}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "companykey",
                        label: "Company Key",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "order_id",
                        label: "Order ID",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "fee_total",
                        label: "Total Fees",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "inspection_date",
                        label: "Inspection Date",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "is_payment_received",
                        label: "Payment Received",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "is_pac",
                        label: "FlexFund",
                        options: {
                            sort: true,
                        }
                    },{
                        name: "is_pac_eligible",
                        label: "FlexFund Eligible",
                        options: {
                            sort: true,
                        }
                    }
                ]}
            />
        </TableContainer>
    );
}

export default Individual;