import React from 'react';

import './index.css';
import App from './App';

import {createRoot} from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";

// Create the application instance.
createRoot(
    document.getElementById('root')
).render(
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </LocalizationProvider>
);

