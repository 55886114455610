import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusLogIndicator from "../../Components/StatusLogIndicator";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

/**
 * UpcomingClosings component.
 *
 * @returns {*}
 * @constructor
 */
function UpcomingClosings() {
    const [isLoading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const today = Moment().format('L');

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        const getResults = async () => {
            setLoading(true);
            const response = await API.send('pay-at-close/upcoming');
            setRecords(response);
            setLoading(false);
        };

        getResults().then(() => true);
    }, []);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <h3 className={'text__bold'}>Upcoming Closings</h3>
                    <div className={'text__muted'}>
                        Your upcoming closings as of {today}.
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table stickyHeader>
                    <colgroup>
                        <col/>
                        <col width={'20%'}/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Description</b></TableCell>
                            <TableCell align={'right'}><b>Closing Date</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton width={'60%'}/>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Skeleton width={'100%'} style={{marginLeft: 'auto'}}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !records.length && (
                            <TableRow>
                                <TableCell colSpan={2} align={'center'}>
                                    <div className={'text__muted'}>
                                        No results available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {records.map((record, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <Link to={`/transaction/${record.id}`}>
                                        <StatusLogIndicator status={record.status}/>
                                        {record.order_full_address}
                                    </Link>
                                    <div>
                                        OID: {record.order_number}, CK: {record.companykey}
                                    </div>
                                </TableCell>
                                <TableCell align={'right'}>
                                    {Formatter.date(record.closing_dte)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default UpcomingClosings;
