import React from "react";
import Chip from "@mui/material/Chip";

/**
 * StatusChip component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function StatusChip(props) {
    const {record} = props;

    /**
     * Returns all error statuses.
     *
     * @returns {string[]}
     */
    const getErrorStatuses = () => {
        return [
            'CANCEL_OTHER',
            'ORDER_DELETED',
            'ORDER_CANCELED',
            'TRANSACTION_CANCELED',
            'CLOSING_FELL_THROUGH',
            'REJECT_NONPAYMENT_TE',
            'CANCEL_NO_SIGNATURE_RECEIVED',
            'CANCELED_PAID_DIRECTLY_TO_INSPECTOR'
        ];
    };


    /**
     * Returns all complete statuses.
     *
     * @returns {string[]}
     */
    const getCompleteStatuses = () => {
        return [
            'CLIENT_PAYMENT_COMPLETE',
            'TE_PAYMENT_COMPLETE',
            'FUNDED'
        ];
    };


    /**
     * Indicates whether or not the transaction is currently marked as complete.
     *
     * @returns {boolean}
     */
    const isComplete = () => {
        return getCompleteStatuses().includes(record.status);
    };


    /**
     * Indicates whether or not the transaction is currently in error.
     *
     * @returns {boolean}
     */
    const isError = () => {
        return !record.status || getErrorStatuses().includes(record.status)
    };


    /**
     * Returns the appropriate chip color.
     *
     * @returns {string}
     */
    const getColor = () => {
        return isError() ? 'error' : (isComplete() ? 'success' : 'primary')
    };

    const getLabel = () => {
        const status = record.status || '';
        const label = status.replaceAll('_', ' ');

        return label.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
        });
    };

    return (
        <Chip label={getLabel()} color={getColor()} size={'small'}/>
    );
}

export default StatusChip;