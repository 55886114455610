import React, {useState} from "react";

import Batches from "./Funding/Batches";
import Payments from "./Funding/Payments";
import TabContainer from "../Components/TabContainer";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

/**
 * Funding component.
 *
 * @returns {*}
 * @constructor
 */
function Funding() {
    const [tab, setTab] = useState('transactions');

    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setTab(updated);
    };

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Funding Activity</h3>

                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Payments" value="transactions"/>
                    <Tab label="Batches" value="batches"/>
                </Tabs>
            </div>

            <TabContainer value={tab} target={'transactions'}>
                <div className={'columns__1'}>
                    <Payments/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'batches'}>
                <div className={'columns__1'}>
                    <Batches/>
                </div>
            </TabContainer>
        </div>
    );
}

export default Funding;
