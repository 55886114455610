import React, {useEffect, useState, Fragment} from "react";

import API from "../Generic/API";
import Formatter from "../Style/Formatter";
import TabContainer from "../Components/TabContainer";
import AccountActions from "./Accounts/AccountActions";
import Promotions from "./Accounts/Promotions";
import Services from "./Accounts/Services";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import {useParams, useNavigate} from "react-router-dom";
import {
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress,
    Snackbar,
} from "@mui/material";

/**
 * Account component.
 *
 * @returns {*}
 * @constructor
 */
function Account(props) {
    const {id} = useParams();
    const {defaultTab} = props;
    const navigate = useNavigate();
    const [tab, setTab] = useState(defaultTab);
    const [record, setRecord] = useState({});
    const [promotions, setPromotions] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [pricingInfo, setPricingInfo] = useState({})
    const [pacInOs, setPacInOs] = useState(false);
    const [toggleLoading, setToggleLoading] = useState(false);
    const [settings, setSettings] = useState({});
    const [offices, setOffices] = useState([]);
    const [officeId, setOfficeId] = useState(null);
    const [isMessageOpen, setMessageOpen] = useState(false);
    const [toggleResponse, setToggleResponse] = useState({});

    /**
     * Loads the account and promotions via the API.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/account/${id}`);

        // Handle missing records.
        if (!response.account) {
            return navigate('/not-found');
        }

        setPromotions(response.promotions);
        setPricingInfo(response.pricing_info);
        setRecord(response.account);
        setPacInOs(response.pac.pac_enabled);
        setSettings(response.pac.settings);
        setOffices(response.offices);
        setOfficeId(response.offices[0].id);

        setLoading(false);
    };


    /**
     * Saves an arbitrary parameter key.
     *
     * @returns {Promise<void>}
     */
    const doPropertySave = async (key, value) => {
        setRecord({
            ...record,
            [key]: value
        });

        await API.send(
            `pay-at-close/account/${record.name}/parameter`,
            'POST',
            {
                key, value
            }
        );
    };

    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setTab(updated);
    };

    /**
     * Reveals the note message.
     */
    const handleMessageOpen = () => {
        setMessageOpen(true);
    };


    /**
     * Hides the note message.
     */
    const handleMessageClose = () => {
        setMessageOpen(false);
    };


    /**
     * Turns on/off PAC in the Online Scheduler
     *
     * @param event
     * @param value
     */
    const doPacInOsSave = async (event, value) => {
        if (value == null){
            return;
        }
        setToggleLoading(true);
        setPacInOs(value)

        const response = await API.send(
            `pay-at-close/account/${record.name}/set-pac-os`,
            'POST',
            {
                value, settings
            }
        )
        setToggleLoading(false)
        setToggleResponse(response)
        setMessageOpen(true)
    };

    /**
     * Loads the target account on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Account Management</h3>

                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Overview" value="overview" />
                    <Tab label="Services" value="services" />
                </Tabs>
            </div>

            <TabContainer value={tab} target={'overview'}>
                <div className={'columns__1'}>
                    <div className={'columns__1'}>
                        <Paper>
                            <div className={'columns__1'}>
                                <div className={'d-flex__justify'}>
                                    <div>
                                        <h3 className={'text__bold'}>Account Details</h3>
                                        <p className={'text__muted'}>
                                            The following details are available for the selected account.
                                        </p>
                                    </div>

                                    {record.name && (
                                        <div className={'transaction__actions'}>
                                            <div className={'d__inline-block'}>
                                                <AccountActions record={record} onReload={getResults}/>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Divider/>

                                {isLoading ? (
                                    <Fragment>
                                        <div className={'columns__3'}>
                                            {Array.from(Array(1).keys()).map((i, j) => {
                                                return (
                                                    <div className={'detail__field'} key={j}>
                                                        <Skeleton
                                                            width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                            height={'1.3em'}
                                                        />
                                                        <Skeleton
                                                            width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                            height={'1.3em'}
                                                            style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className={'columns__3'}>
                                            <div className={'detail__field'}>
                                                <b>Company Key</b>
                                                <div>
                                                    {record.name}
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Paper>

                        {record.name && (
                            <Paper>
                                <div className={'columns__1'}>
                                    <div className={'d-flex__justify'}>
                                        <h3 className={'text__bold'}>Deposit Verification</h3>
                                    </div>

                                    {(!record.funding_status || record.funding_status === 'rejected' || record.funding_status === 'unverified') && (
                                        <Fragment>
                                            <Alert severity={'warning'}>
                                                <div className={'d-flex-justify'}>
                                                    <div>
                                                        This account has not been verified yet, please use the button below to enqueue a charge and begin the validation process.
                                                    </div>
                                                </div>
                                            </Alert>

                                            <div>
                                                <Button
                                                    variant={'contained'}
                                                    disabled={isLoading}
                                                    onClick={() => doPropertySave('funding_status', 'queued')}
                                                >
                                                    Attempt Charge
                                                </Button>
                                            </div>
                                        </Fragment>
                                    )}

                                    {record.funding_status === 'queued' && (
                                        <Fragment>
                                            <Alert severity={'info'}>
                                                <div className={'d-flex-justify'}>
                                                    <div>
                                                        This account is currently queued for verification.
                                                    </div>
                                                </div>
                                            </Alert>

                                            <div>
                                                <Button
                                                    color={'error'}
                                                    variant={'contained'}
                                                    disabled={isLoading}
                                                    onClick={() => doPropertySave('funding_status', 'unverified')}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Fragment>
                                    )}

                                    {record.funding_status === 'delivered' && (
                                        <Fragment>
                                            <Alert severity={'info'}>
                                                <div className={'d-flex-justify'}>
                                                    <div>
                                                        A verification total of {Formatter.currency(record.verification_amount)} was processed. Please confirm with the client that this balance had been received.
                                                    </div>
                                                </div>
                                            </Alert>

                                            <div>
                                                <Button
                                                    variant={'contained'}
                                                    disabled={isLoading}
                                                    onClick={() => doPropertySave('funding_status', 'verified')}
                                                >
                                                    Yes
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    color={'error'}
                                                    variant={'contained'}
                                                    disabled={isLoading}
                                                    onClick={() => doPropertySave('funding_status', 'unverified')}
                                                >
                                                    No
                                                </Button>
                                            </div>
                                        </Fragment>
                                    )}

                                    {record.funding_status === 'verified' && (
                                        <Fragment>
                                            <Alert severity={'success'}>
                                                <div className={'d-flex-justify'}>
                                                    <div>
                                                        This account has been verified for funding!
                                                    </div>
                                                </div>
                                            </Alert>

                                            <div>
                                                <Button
                                                    color={'error'}
                                                    variant={'contained'}
                                                    disabled={isLoading}
                                                    onClick={() => doPropertySave('funding_status', 'unverified')}
                                                >
                                                    Revoke
                                                </Button>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </Paper>
                        )}
                        {record.name && (
                            <div className={'d__inline-block'}>
                                <Promotions
                                    record={record}
                                    promotions={promotions}
                                    onReload={getResults}
                                    pricing={pricingInfo}
                                />
                            </div>
                        )}
                        <Paper>
                            <div className={'columns__1'}>
                                <div className={'detail__field'}>
                                    <b>Enable PAC in the Online Scheduler</b>
                                    {isLoading ? (
                                        <Fragment>
                                            <div className={'columns__3'}>
                                                {Array.from(Array(1).keys()).map((i, j) => {
                                                    return (
                                                        <div className={'detail__field'} key={j}>
                                                            <Skeleton
                                                                width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                                height={'1.3em'}
                                                            />
                                                            <Skeleton
                                                                width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                                height={'1.3em'}
                                                                style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ToggleButtonGroup
                                                    exclusive
                                                    disabled={toggleLoading}
                                                    value={pacInOs}
                                                    onChange={(event, value) => doPacInOsSave(event, value)}
                                                >
                                                    <ToggleButton
                                                        size={'small'}
                                                        value={true}
                                                        children={'Yes'}
                                                    />
                                                    <ToggleButton
                                                        size={'small'}
                                                        value={false}
                                                        children={'No'}
                                                    />
                                                </ToggleButtonGroup>
                                                {toggleLoading &&
                                                    <CircularProgress size={24} sx={{ marginLeft: '8px' }}/>
                                                }
                                            </div>
                                            <p className={'text__muted'}>
                                                This will modify the setting for every office in the Company
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Snackbar
                                open={isMessageOpen}
                                autoHideDuration={6000}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                onClose={handleMessageClose}
                            >
                                <Alert onClose={handleMessageClose} severity={toggleResponse.status} sx={{ width: '100%' }}>
                                    {toggleResponse.message}
                                </Alert>
                            </Snackbar>
                        </Paper>
                    </div>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'services'}>
                <div className={'columns__1'}>
                    <Paper>
                        {officeId !== null ?
                            <Services
                                ck={id}
                                offices={offices}
                                officeId={officeId}
                                setOfficeId={setOfficeId}
                            />
                        : '' }
                    </Paper>
                </div>
            </TabContainer>
        </div>
    );
}

export default Account;
