import React from "react";

/**
 * TabContainer component.
 *
 * @return {*}
 */
function TabContainer(props) {
    const {value, target, children} = props;

    return value === target ? <div>{children}</div> : null;
}

export default TabContainer;