import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import PaymentsTable from "./PaymentsTable";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Payments component.
 *
 * @returns {*}
 * @constructor
 */
function Payments() {
    const [search, setSearch] = useState('');
    const [recordsLength, setRecordsLength] = useState(0);
    const [isLoading, setLoading] = useState(false);

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <div className={'block__heading block__heading--bordered'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Payments {!isLoading ? `(${recordsLength})` : ''}</h3>
                            <p className={'text__muted'}>Your results are displayed below.</p>
                        </div>

                        <div>
                            <TextField
                                label="Search..."
                                variant="outlined"
                                sx={{width: '280px'}}
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                            <IconButton
                                onClick={() => {
                                    document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                }}
                                style={{marginTop: '0.3em'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <PaymentsTable
                    search={search}
                    recordsLength={recordsLength}
                    setRecordsLength={setRecordsLength}
                    isLoading={isLoading}
                    setLoading={setLoading}
                />
            </Paper>
        </div>
    );
}

export default Payments;
