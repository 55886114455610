import React, {Fragment, useState} from "react";

import API from "../../Generic/API";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/**
 * FundingActions component.
 *
 * @returns {*}
 * @constructor
 */
function FundingActions(props) {
    const {record, disabled, onReload} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [note, setNote] = useState('');

    const open = Boolean(anchorEl);
    const isQueued = record.status === 'Queued';
    const isDelivered = record.status === 'Delivered';

    /**
     * Used to revert a full funding transaction.
     */
    const doRevert = async () => {
        const {id, status} = record;

        // Enforce that we can only cancel delivered transactions.
        if (status !== 'Delivered') {
            return;
        }

        // Perform the update.
        setLoading(true);

        await API.send(
            `pay-at-close/funding/${id}/revert`,
            'POST',
            {
                note
            }
        );

        handleFormClose();

        // Reload the table results.
        if (onReload) {
            await onReload();
        }

        setLoading(false);
    };


    /**
     * Saves an arbitrary parameter key.
     *
     * @returns {Promise<void>}
     */
    const doPropertySave = async (key, value) => {
        setLoading(true);

        await API.send(
            `pay-at-close/funding/${record.id}/parameter`,
            'POST',
            {
                key, value
            }
        );

        // Reload the table results.
        if (onReload) {
            await onReload();
        }

        setLoading(false);
    };


    /**
     * Removes a transaction from the queue.
     */
    const doCancel = async () => {
        const {transaction_id} = record;

        // Prompt confirmation to the user.
        if (!window.confirm('Are you sure you would like to remove this transaction from the funding queue?')) {
            return;
        }

        // Perform the update.
        setLoading(true);

        if (record.revert) {
            await API.send(
                `pay-at-close/funding/${record.id}/revert-cancel`,
                'POST'
            );
        } else {
            await API.send(
                `pay-at-close/transaction/${transaction_id}/parameter`,
                'POST',
                {
                    key: 'inspector_needs_paid',
                    value: false
                }
            );
        }

        // Reload the table results.
        if (onReload) {
            await onReload();
        }

        setLoading(false);
    };

    /**
     * Reveals the note form.
     */
    const handleFormOpen = () => {
        setFormOpen(true);
    };


    /**
     * Hides the note form.
     */
    const handleFormClose = () => {
        setFormOpen(false);
    };


    /**
     * Expands the menu.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Collapses the menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {isQueued ? (
                <Fragment>
                    <IconButton
                        disabled={isLoading}
                        onClick={() => doCancel()}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Fragment>
            ) : null}

            {isDelivered ? (
                <div>
                    <Dialog open={isFormOpen} onClose={handleFormClose}>
                        <DialogTitle>
                            <h3>Add Reverse Transaction Note</h3>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                sx={{width: 360}}
                                rows={6}
                                multiline
                                onChange={(event) => setNote(event.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleFormClose} disabled={isLoading}>Cancel</Button>
                            <Button onClick={doRevert} disabled={isLoading}>Save</Button>
                        </DialogActions>
                    </Dialog>
                    <Fragment>
                        <IconButton onClick={handleMenuOpen} disabled={disabled || isLoading}>
                            <MoreHorizIcon/>
                        </IconButton>
                        <Menu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                        >
                            {[
                                !record.bounced && [
                                    !record.revert ? [
                                        !record.is_verification ? (
                                            <MenuItem key={`${record.id}_reverse`} onClick={async () => {
                                                handleMenuClose();
                                                handleFormOpen();
                                            }}>Reverse Transaction</MenuItem>
                                        ) : null,

                                        <MenuItem key={`${record.id}_bounce`} onClick={async () => {
                                            handleMenuClose();
                                            await doPropertySave('bounced', true)
                                        }}>Mark as "Bounced"</MenuItem>
                                    ] : null,

                                    /*
                                    If we've already attempted to revert the transaction, we'll handle this as a cancel since
                                    the only way this is technically possible is if we're awaiting funding on a pending reversal.
                                    */
                                    record.revert ? (
                                        <MenuItem key={`${record.id}_cancel`} onClick={async () => {
                                            handleMenuClose();
                                            await doCancel();
                                        }}>Cancel Reversal</MenuItem>
                                    ) : null
                                ],

                                record.bounced ? (
                                    <MenuItem key={`${record.id}_deliver`} onClick={async () => {
                                        handleMenuClose();
                                        await doPropertySave('bounced', false)
                                    }}>Mark as "Delivered"</MenuItem>
                                ) : null
                            ]}
                        </Menu>
                    </Fragment>
                </div>
            ) : null}
        </div>
    );
}

export default FundingActions;