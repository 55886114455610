import React, {useEffect, useState} from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import moment from "moment";

import Grouped from "./InspectionCountAndRevenue/Grouped";
import Individual from "./InspectionCountAndRevenue/Individual";
import TabContainer from "../../Components/TabContainer";
import API from "../../Generic/API";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import {MobileDatePicker} from "@mui/x-date-pickers";
import Box from '@mui/material/Box';

/**
 * InspectionCountAndRevenue component.
 *
 * @returns {*}
 * @constructor
 */
function InspectionCountAndRevenue() {
    const [tab, setTab] = useState('enabled');
    const [reportTitle, setReportTitle] = useState('');
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-D'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-D'));
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const drawerWidth = 280;

    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setRecords([]);
        setTab(updated);
    };

    /**
     * Loads all report results based on the input criteria.
     */
    const getReport = async () => {
        setLoading(true);

        let reportUrlPrefix = '';

        if (tab === 'individual') {
            reportUrlPrefix = `pay-at-close/reporting/revenues/individual?`;
        } else {
            reportUrlPrefix = `pay-at-close/reporting/revenues/${tab.toLowerCase()}?`;
        }

        let startDateFormatted = startDate;
        let endDateFormatted = endDate;

        if (startDate instanceof Object) {
            startDateFormatted = startDate.format('YYYY-MM-DD');
        }

        if (endDate instanceof Object) {
            endDateFormatted = endDate.format('YYYY-MM-DD');
        }

        const response = await API.send(reportUrlPrefix + `start_date=${startDateFormatted}&end_date=${endDateFormatted}`, 'GET');

        if (tab === 'individual') {
            setReportTitle('Individual Inspections');
        } else {
            setReportTitle(`${tab} Inspection Count and Revenue (${response.length})`);
        }

        setRecords(response);
        setLoading(false);
    };

    useEffect(() => {
        getReport().then(() => true);
    }, [tab]);

    return (
        <div className={'drawer__page'}>
            <Drawer
                    className={'drawer'}
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                    }}
                >
                <div className={'columns__1'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Inspection Count and Revenue</h3>
                            <p className={'text__muted'}>Please use the following filters to customize your report.</p>
                        </div>
                    </div>

                    <div>
                        <div className={'d__inline-block mr__3'}>
                            <MobileDatePicker
                                label="Start Date"
                                inputFormat="YYYY-MM-DD"
                                value={startDate}
                                onChange={(value) => setStartDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>

                        <div className={'d__inline-block mr__3'} style={{ marginTop: '1em' }}>
                            <MobileDatePicker
                                label="End Date"
                                inputFormat="YYYY-MM-DD"
                                value={endDate}
                                onChange={(value) => setEndDate(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </div>
                    <div className={'paper__footer'}>
                        <Button onClick={getReport} color={'primary'} variant={'contained'}>Run</Button>
                    </div>
                </div>
            </Drawer>

            <div className={'columns__1'}>
                <Paper className={'table__block'}>
                    <div className={'block__heading block__heading--bordered'}>
                        <Box className={'transactions__heading'}>
                            <div className={'d-flex__justify tabbed__heading'}>
                                <Tabs value={tab} onChange={handleTabChange}>
                                    <Tab label="Enabled" value="enabled"/>
                                    <Tab label="Disabled" value="disabled"/>
                                    <Tab label="Individual" value="individual"/>
                                </Tabs>

                                <div>
                                    <TextField
                                        label="Search..."
                                        variant="outlined"
                                        sx={{width: '280px'}}
                                        value={search}
                                        onChange={(event) => setSearch(event.target.value)}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                        }}
                                        style={{marginTop: '0.3em'}}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        </Box>
                    </div>

                    <TabContainer value={tab} target={'enabled'}>
                        <div className={'columns__1'}>
                            <Grouped isLoading={isLoading} records={records} eligible={'enabled'} search={search} />
                        </div>
                    </TabContainer>

                    <TabContainer value={tab} target={'disabled'}>
                        <div className={'columns__1'}>
                            <Grouped isLoading={isLoading} records={records} eligible={'disabled'} search={search} />
                        </div>
                    </TabContainer>

                    <TabContainer value={tab} target={'individual'}>
                        <div className={'columns__1'}>
                            <Individual isLoading={isLoading} records={records} search={search} />
                        </div>
                    </TabContainer>
                </Paper>
            </div>
        </div>
    );

}

export default InspectionCountAndRevenue;
