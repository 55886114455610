import React, {useEffect, useRef, useState} from "react";

import moment from "moment";

import API from "../../Generic/API";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";

/**
 * ServiceSelectionComponent
 */
function Services(props) {
    const {ck, offices, officeId, setOfficeId} = props;
    const ref = useRef('first');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [changeLog, setChangeLog] = useState([]);
    const [servicesSettingId, setServicesSettingId] = useState(null);
    const [inspectionTypesSettingId, setInspectionTypesSettingId] = useState(null);
    const [servicesOptInSettingId, setServicesOptInSettingId] = useState(null);
    const [inspectionTypesOptInSettingId, setInspectionTypesOptInSettingId] = useState(null);
    const [services, setServices] = useState([]);
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [selectedServices, setSelectedServices] = useState(services || []);
    const [selectedInspectionTypes, setSelectedInspectionTypes] = useState(inspectionTypes || []);
    const [serviceIds, setServiceIds] = useState([]);
    const [allServicesSelected, setAllServicesSelected] = useState([]);
    const [servicesIndeterminate, setServicesIndeterminate] = useState([]);
    const [inspectionTypeIds, setInspectionTypeIds] = useState([]);
    const [allInspectionTypesSelected, setAllInspectionTypesSelected] = useState([]);
    const [inspectionTypesIndeterminate, setInspectionTypesIndeterminate] = useState([]);
    const [saveErrorMessage, setSaveErrorMessage] = useState('');
    const [disableSave, setDisableSave] = useState(false);

    /**
     * Loads the target account services.
     */
    useEffect(() => {
        const serviceIdsTmp = selectedServices.map((service) => service.id);
        const allServicesTmp = services.length === selectedServices.length;
        const inspectionTypeIdsTmp = selectedInspectionTypes.map((type) => type.id);
        const allInspectionTypesTmp = inspectionTypes.length === selectedInspectionTypes.length;

        setServiceIds(serviceIdsTmp);
        setAllServicesSelected(allServicesTmp);
        setServicesIndeterminate(!!selectedServices.length && !allServicesTmp);

        setInspectionTypeIds(inspectionTypeIdsTmp);
        setAllInspectionTypesSelected(allInspectionTypesTmp);
        setInspectionTypesIndeterminate(!!selectedInspectionTypes.length && !allInspectionTypesTmp);

        if (ref.current === 'first') {
            getResults().then(() => true);
        }

        ref.current = false;
    }, [selectedServices, selectedInspectionTypes]);

    /**
     *
     */

    /**
     * Saves the data to the database
     */
    const saveData = async () => {

    };

    /**
     * Loads any related data.
     */
    const getResults = async () => {
        setLoading(true);
        getServicesAndInspectionTypes(officeId).then(() => setLoading(false));
    };

    /**
     * Loads available services and inspection types via the API.
     */
    const getServicesAndInspectionTypes = async (oid) => {
        const response = await API.send(`pay-at-close/account/${ck}/services-and-inspection-types/${oid}`);
        const blankSettings = `["none"]`;

        resetServicesAndInspections();

        if (!response) {
            return;
        }

        if (response['changelog'] !== 'none') {
            setChangeLog(response['changelog']);
        }

        if (JSON.stringify(response['services']) !== blankSettings && response['services'] !== 'none') {
            setServices(response['services']);
        }

        if (JSON.stringify(response['inspection-types']) !== blankSettings && response['inspection-types'] !== 'none') {
            setInspectionTypes(response['inspection-types']);
        }

        if (JSON.stringify(response['settings']) === blankSettings) {
            setSelectedServices([]);
            setSelectedInspectionTypes([]);

            return;
        } else {
            const pacServices = response['settings'].filter((setting) => setting.key === 'PAC_SERVICES')[0];
            const pacInspectionTypes = response['settings'].filter((setting) => setting.key === 'PAC_INSPECTION_TYPES')[0];
            const pacServicesOptIn = response['settings'].filter((setting) => setting.key === 'PAC_SERVICES_OPT_IN');
            const pacInspectionTypesOptIn = response['settings'].filter((setting) => setting.key === 'PAC_INSPECTION_TYPES_OPT_IN');

            if (pacServices.value !== 'Array') {
                setSelectedServices(JSON.parse(pacServices.value));
            }

            setServicesSettingId(pacServices.id);

            if (pacInspectionTypes.value !== 'Array') {
                setSelectedInspectionTypes(JSON.parse(pacInspectionTypes.value));
            }

            setInspectionTypesSettingId(pacInspectionTypes.id);

            if (pacServicesOptIn.length > 0) {
                setServicesOptInSettingId(pacServicesOptIn[0].id)
            } else {
                setSaveErrorMessage('There is an issue with the settings. Please try again later.');
                setDisableSave(true);
            }

            if (pacInspectionTypesOptIn.length > 0) {
                setInspectionTypesOptInSettingId(pacInspectionTypesOptIn[0].id);
            } else {
                setSaveErrorMessage('There is an issue with the settings. Please try again later.');
                setDisableSave(true);
            }
        }
    }

    /**
     * Resets all the data variables in the case where the response isn't valid
     */
    const resetServicesAndInspections = () => {
        setServices([]);
        setServicesSettingId(null);
        setSelectedServices([]);
        setServiceIds([]);
        setAllServicesSelected([]);
        setServicesIndeterminate([]);
        setInspectionTypes([]);
        setInspectionTypesSettingId(null);
        setSelectedInspectionTypes([]);
        setInspectionTypeIds([]);
        setAllInspectionTypesSelected([]);
        setInspectionTypesIndeterminate([]);
        setChangeLog([]);
    };

    /**
     * Handles the service selections.
     *
     * @param id
     * @param value
     */
    const handleServiceChange = (id, value) => {
        if (value) {
            if (!selectedServices.includes(id)) {
                setSelectedServices(selectedServices.concat([id]));
                ref.current = true;
            }
        } else {
            const i = selectedServices.indexOf(id);

            if (i > -1) {
                setSelectedServices(
                    [...selectedServices.slice(0, i), ...selectedServices.slice(i + 1)]
                );
                ref.current = true;
            }
        }
    };

    /**
     * Handles the inspection type selections.
     *
     * @param id
     * @param value
     */
    const handleInspectionTypeChange = (id, value) => {
        if (value) {
            if (!selectedInspectionTypes.includes(id)) {
                setSelectedInspectionTypes(selectedInspectionTypes.concat([id]));
                ref.current = true;
            }
        } else {
            const i = selectedInspectionTypes.indexOf(id);

            if (i > -1) {
                setSelectedInspectionTypes(
                    [...selectedInspectionTypes.slice(0, i), ...selectedInspectionTypes.slice(i + 1)]
                );
                ref.current = true;
            }
        }
    }

    /**
     * Selects all available services.
     */
    const handleServiceSelectAll = () => {
        setSelectedServices(services.map(service => service.id));
        ref.current = true;
    };

    /**
     * Deselects all services.
     */
    const handleServiceDeselectAll = () => {
        setSelectedServices([]);
        ref.current = true;
    };

    /**
     * Selects all available inspection types.
     */
    const handleInspectionTypeSelectAll = () => {
        setSelectedInspectionTypes(inspectionTypes.map(inspectionType => inspectionType.id));
        ref.current = true;
    };

    /**
     * Deselects all inspection types.
     */
    const handleInspectionTypeDeselectAll = () => {
        setSelectedInspectionTypes([]);
        ref.current = true;
    }

    /**
     * Selects a different office
     */
    const setOfficeSelect = async (value) => {
        setOfficeId(value);

        setLoading(true);
        await getServicesAndInspectionTypes(value);
        setLoading(false);
        setSaveErrorMessage('');
    };

    /**
     * Syncs the inspection type / service selections to the DB.
     */
    const sync = async () => {
        setSaving(true);
        setSaveErrorMessage('');
        const response = await API.send(
            `pay-at-close/account/${ck}/set-services-and-inspection-types`,
            'POST',
            {
                location: 'guardian',
                officeId: officeId,
                services: JSON.stringify(selectedServices),
                inspectionTypes: JSON.stringify(selectedInspectionTypes),
                settings: {
                    services: servicesSettingId,
                    inspectionTypes: inspectionTypesSettingId,
                    servicesOptIn: servicesOptInSettingId,
                    inspectionTypesOptIn: inspectionTypesOptInSettingId
                }
            }
        );

        if (response.status === 'error') {
            setSaveErrorMessage(response.message);
        } else {
            getResults().then(() => true);
        }

        setSaving(false);
    };

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{padding: '10px'}}>
                        <h3 className={'text__bold'}>Services and Inspection Types</h3>

                        <p>
                            This section can be used to customize which of the offerings for this account and office should be eligible for FlexFund. The
                            changes here will be reflected on the order form as well as the online scheduler.
                        </p>
                        <div>
                            { saveErrorMessage !== '' ?
                            <Alert severity={'error'}>{saveErrorMessage}</Alert>
                            : (saving ?
                            <Alert severity={'info'}>Currently saving your changes. Please wait.</Alert> :
                            <Alert severity={'success'}>Your changes are up to date.</Alert>
                            )}
                        </div>
                        <div style={{marginTop: '1em'}}>
                            {offices.length ?
                                <FormControl>
                                    <Grid container spacing={8}>
                                        <Grid item>
                                            <InputLabel id="office-select-label">Offices</InputLabel>
                                            <Select
                                                labelId="office-select-label"
                                                id="office-select"
                                                label="Office"
                                                value={officeId}
                                                onChange={(event) => setOfficeSelect(event.target.value)}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                {offices.map((element, index) => {
                                                return <MenuItem key={`search-term-${index}`} value={element.id}>{element.name}</MenuItem>;
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item style={{marginTop: '0.5em'}}>
                                            <Button variant="contained" disabled={saving || disableSave} onClick={() => sync()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            : null}
                        </div>
                    </div>

                    <Grid container>
                        <Grid item xs={6}>
                            {!loading ?
                            <div style={{maxHeight: '650px', overflowY: 'scroll'}}>
                                <Grid container>
                                    <Grid item>
                                    {services.length ?
                                        <div>
                                            <Checkbox
                                                color={"primary"}
                                                checked={allServicesSelected}
                                                onChange={() => !selectedServices.length ? handleServiceSelectAll() : handleServiceDeselectAll()}
                                                indeterminate={servicesIndeterminate}
                                            />

                                            Services
                                        </div> : null}

                                    {services.map((service, i) =>
                                        <div key={i} style={{paddingLeft: '1.5em'}}>
                                            <Checkbox
                                                color={"primary"}
                                                checked={selectedServices.includes(service.id)}
                                                onChange={(e) => handleServiceChange(service.id, e.currentTarget.checked)}
                                            />

                                            {service.name || '(N/A)'}
                                        </div>
                                    )}
                                    </Grid>
                                    <Grid item style={{flexWrap: 'wrap'}}>
                                    {inspectionTypes.length ?
                                        <div>
                                            <Checkbox
                                                color={"primary"}
                                                checked={allInspectionTypesSelected}
                                                onChange={() => !selectedInspectionTypes.length ? handleInspectionTypeSelectAll() : handleInspectionTypeDeselectAll()}
                                                indeterminate={inspectionTypesIndeterminate}
                                            />

                                            Inspection Types
                                        </div> : null}

                                    {inspectionTypes.map((type, i) =>
                                        <div key={i} style={{paddingLeft: '1.5em'}}>
                                            <Checkbox
                                                color={"primary"}
                                                checked={selectedInspectionTypes.includes(type.id)}
                                                onChange={(e) => handleInspectionTypeChange(type.id, e.currentTarget.checked)}
                                            />

                                            {type.name || '(N/A)'}
                                        </div>
                                    )}
                                    </Grid>
                                </Grid>
                            </div>
                            : 'Loading...'}
                        </Grid>
                        <Grid item xs={6}>
                            {!loading ?
                            <div style={{maxHeight: '100%', overflowY: 'scroll'}}>
                                <TableContainer component={'div'} className={'transactions__list'}>
                                    <MUIDataTable
                                        className={'table__grid'}
                                        data={changeLog.map(record => {
                                            return {
                                                changed_by: record.changed_by,
                                                status: record.status,
                                                location: record.location,
                                                name: record.name,
                                                type: record.type,
                                                created_at: moment(record.created_at, 'YYYY-MM-DDThh:mm:ss.000000Z').format('MMM Do YYYY, h:mm:ss a'),
                                            };
                                        })}
                                        options={
                                            {
                                                selectableRows: "none",
                                                responsive: 'standard',
                                                rowsPerPage: 100,
                                                rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                                filter: false,
                                                search: false,
                                                print: false,
                                                download: true,
                                                viewColumns: true,
                                                customToolbar: null,
                                                fixedHeader: true,
                                                tableBodyHeight: 'calc(100vh - 230px)',
                                            }
                                        }
                                        columns={[
                                            {
                                                name: "changed_by",
                                                label: "Changed By",
                                                options: {
                                                    sort: true,
                                                }
                                            }, {
                                                name: "location",
                                                label: "Location",
                                                options: {
                                                    sort: true,
                                                }
                                            }, {
                                                name: "name",
                                                label: "Name",
                                                options: {
                                                    sort: true,
                                                }
                                            }, {
                                                name: "type",
                                                label: "Type",
                                                options: {
                                                    sort: true,
                                                }
                                            }, {
                                                name: "status",
                                                label: "Turned On/Off",
                                                options: {
                                                    sort: true,
                                                }
                                            },{
                                                name: "created_at",
                                                label: "Date",
                                                options: {
                                                    sort: true,
                                                }
                                            }
                                        ]}
                                    />
                                </TableContainer>
                            </div>
                            : 'Loading...'}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Services;