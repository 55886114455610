import {Navigate, useLocation} from "react-router-dom";
import React, {createContext, useState} from "react";
import API from "../Generic/API";

const authContext = createContext();

export function useAuth() {
    const [authenticated, setAuthenticated] = useState(!!localStorage.getItem('auth-token'));

    return {
        authenticated,
        login(username, password) {
            return new Promise(async (success, reject) => {
                const request = await fetch(API.url(`authenticate`), {
                    method: 'POST',
                    body: JSON.stringify({
                        username, password
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                const response = await request.json();

                // Handle validation errors.
                if(response.status === 'error'){
                    return reject(response.message);
                }

                // Share user storage throughout the application.
                localStorage.setItem('auth-user', JSON.stringify(response.user));
                localStorage.setItem('auth-token', response.token);
                localStorage.setItem('auth-username', username);

                setAuthenticated(true);
                success();
            });
        },
        logout() {
            return new Promise((response) => {
                localStorage.setItem('auth-token', '');
                setAuthenticated(false);
                response();
            });
        },
    };
}

export function AuthProvider({ children }) {
    const authenticated = useAuth();

    return <authContext.Provider value={authenticated}>{children}</authContext.Provider>;
}

export function RequireAuth({ children }) {
    const { authenticated } = useAuth();
    const location = useLocation();
    const loginUrl = `/login?url=${encodeURIComponent(location.pathname)}`;

    return authenticated ? children : <Navigate to={loginUrl} />;
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}