import React, {Fragment, useEffect, useState} from "react";

import API from "../../Generic/API";
import DownloadCsv from "../../Generic/DownloadCsv";

import Formatter from "../../Style/Formatter";
import FundingActions from "./FundingActions";

import {Link} from "react-router-dom";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

/**
 * PaymentsTable component.
 *
 * @returns {*}
 * @constructor
 */
function PaymentsTable(props) {
    const {search, recordsLength, setRecordsLength, isLoading, setLoading, condensed} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorRecord, setAnchorRecord] = useState({});
    const [isDownloading, setDownloading] = useState(false);
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [currentSortOrder, setSortOrder] = useState({name: null, direction: null});

    /**
     * Organizes parameters to send to the API
     *
     * @returns {Object}
     */
    const getParametersForUrl = (page = 0, rows = 100, sortOrder = {name: null, direction: null}, download = false) => {
        setCurrentPage(page);
        setRowsPerPage(rows);
        setSortOrder(sortOrder);

        const encodedSearch = search == '' ? null : encodeURIComponent(search);
        const start = page * rows;

        return {
            start: start,
            offset: rows,
            sortOrder: sortOrder,
            search: search,
            download: download,
        };
    };

    /**
     * Loads all of the funding results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async (page, rows, sortOrder) => {
        setLoading(true);

        const parameters = getParametersForUrl(page, rows, sortOrder);
        const response = await API.send("pay-at-close/funding/search/" + JSON.stringify(parameters));

        setRecords(response.records);
        setRecordsLength(response.count);

        setLoading(false);
    };

    /**
     * Loads the transactions in a downloadable format
     *
     * @returns {String}
     */
    const getDownloadedResults = async () => {
        setDownloading(true);

        const parameters = getParametersForUrl(currentPage, rowsPerPage, currentSortOrder, true);
        const response = await API.send("pay-at-close/funding/search/" + JSON.stringify(parameters));

        setDownloading(false);

        return response.records;
    };

    /**
     * Load records on search parameter changing.
     */
    useEffect(() => {
        // we need to make sure the calls to the backend are only done when the user is finished typing
        const handler = setTimeout(() => getResults().then(() => true), 500);

        return () => clearTimeout(handler);
    }, [search]);

    /**
     * Reveals an individual transactions' notice.
     *
     * @param event
     * @param record
     */
    const handleNoticeOpen = (event, record) => {
        setAnchorEl(event.currentTarget);
        setAnchorRecord(record);
    };

    /**
     * Closes an individual transactions' notice.
     */
    const handleNoticeClose = () => {
        setAnchorEl(null);
        setAnchorRecord({});
    };

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {isLoading && <LinearProgress/>}
            <MUIDataTable
                className={'table__grid'}
                data={records}
                options={
                    {
                        searchText: search,
                        selectableRows: "none",
                        responsive: 'standard',
                        count: recordsLength,
                        page: currentPage,
                        rowsPerPage: rowsPerPage,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 300px)',
                        serverSide: true,
                        onDownload: (buildHead, buildBody, columns, data) => {
                            // because we're waiting on the data we're gonna do part of the CSV creation ourselves
                            const result = getDownloadedResults().then(
                                (responseData) => {
                                    const csv = `${buildHead(columns)}${buildBody(responseData).replace(/'-\$/g, '-$')}`.trim();
                                    DownloadCsv(csv, 'funding.csv');
                                }
                            );

                            return false;
                        },
                        onTableChange: (action, tableState) => {
                            if (['changePage', 'changeRowsPerPage', 'sort'].indexOf(action) > -1) {
                                getResults(tableState.page, tableState.rowsPerPage, tableState.sortOrder).then(() => true);

                                // scroll to the top of the table since results have been loaded in
                                const table = document.querySelector('table');
                                table.scrollIntoView(true);
                            }
                        },
                        customRowRender: (data, i) => {
                            const record = records[i];
                            const isQueued = record.status === 'Queued';
                            const isDelivered = record.status === 'Delivered';
                            const isQueuedRevert = record.status === 'Queued' && record.revert;

                            return (
                                <TableRow key={i}>
                                    <TableCell width={'28%'}>
                                        <div className={'d-flex__justify'}>
                                            {isQueued && (
                                                <div className={'mr__2'}>
                                                    <AccessTimeIcon/>
                                                </div>
                                            )}
                                            <div className={'w-100 d-flex__justify'}>
                                                {record.record_type === 'account' || record.is_verification ? (
                                                    <div>
                                                        <span>Account Verification: </span>
                                                        <Link to={`/account/${record.company_key}`}>
                                                            {record.company_key}
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Link to={`/transaction/${record.transaction_id}`}>
                                                            {record.transaction_address}
                                                        </Link>
                                                        <div>
                                                            OID: {record.oid}, CK: {record.company_key}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell width={'20%'}>
                                        {!isQueuedRevert ? Formatter.dateTime(record.date) : null}
                                    </TableCell>
                                    <TableCell width={'15%'}>
                                        <div className={'d-flex'}>
                                            <div className={'mr__1'}>
                                                <span className={'mr__1'}>{record.status}</span>

                                                {record.revert && isDelivered ? (
                                                    <span className={'text__danger text__bold'}>(Reversed)</span>
                                                ) : null}
                                            </div>

                                            {record.notice && (
                                                <Fragment>
                                                    <WarningAmberIcon
                                                        color={'warning'}
                                                        onMouseEnter={(event) => handleNoticeOpen(event, record)}
                                                        onMouseLeave={handleNoticeClose}
                                                    />

                                                    <Popover
                                                        sx={{
                                                            pointerEvents: 'none',
                                                        }}
                                                        open={anchorRecord.id === record.id}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        onClose={handleNoticeClose}
                                                        disableRestoreFocus
                                                    >
                                                        <Typography sx={{p: 1}}>{record.notice}</Typography>
                                                    </Popover>
                                                </Fragment>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell width={'15%'}>
                                        {Formatter.currency(record.amount)}
                                    </TableCell>
                                    <TableCell width={'15%'}>
                                        {!isQueued && (record.bounced ? 'Yes' : 'No')}
                                    </TableCell>
                                    <TableCell>
                                        <FundingActions
                                            record={record}
                                            onReload={getResults}
                                            disabled={isLoading}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "transaction",
                        label: "Transaction",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "oid",
                        options: {
                            display: false,
                            download: false,
                        }
                    }, {
                        name: "company_key",
                        options: {
                            display: false,
                            download: false,
                        }
                    }, {
                        name: "transaction_address",
                        options: {
                            display: false,
                            download: false,
                        }
                    }, {
                        name: "date",
                        label: "Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "status",
                        label: "Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "amount",
                        label: "Amount",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "bounced",
                        label: "Bounced",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "actions",
                        label: "Actions",
                        options: {
                            sort: false,
                            download: false,
                        }
                    }
                ]}
            />
        </TableContainer>
    );
}

export default PaymentsTable;
