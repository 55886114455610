import React, {useEffect, useState} from "react";

import {useAuth} from "./../Providers/Authentication";

import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

/**
 * Login component.
 *
 * @returns {*}
 * @constructor
 */
function Login() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {authenticated, login} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    // Configure our page storage.
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);

    /**
     * If the user is already authenticated, redirect to the dashboard.
     */
    useEffect(() => {
        if (authenticated) {
            navigate('/');
        }
    }, []);


    /**
     * Redirect on user authentication.
     */
    const handleLogin = () => {
        setError('');
        setLoading(true);

        login(username, password)
            .then(() => {
                // Need to do a hard load here to refresh the top navigation, otherwise
                // the user will be directed elsewhere in the site without menu items.
                //
                const loginUrl = searchParams.get('url');
                window.location = loginUrl ? decodeURIComponent(loginUrl) : "/";
            })
            .catch((message) => {
                setLoading(false);
                setError(message);
            });
    };


    /**
     * Allow login on enter key.
     *
     * @param event
     */
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };

    return (
        <div className={'login__wrapper'}>
            <Paper className={'login__container'}>
                <div className={'columns__1'}>
                    <div>
                        <img
                            src={'/logo.png'}
                            alt="Guardian Inspection Payments"
                            className={'login__logo'}
                        />
                    </div>

                    {error && <Alert severity="error" children={<div>{error}</div>}/>}

                    <h3>Please sign in below...</h3>

                    <TextField
                        autoFocus
                        label="Username"
                        variant="outlined"
                        disabled={isLoading}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setUsername(event.target.value)}
                    />

                    <TextField
                        type="password"
                        label="Password"
                        variant="outlined"
                        disabled={isLoading}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setPassword(event.target.value)}
                    />

                    <div className={'columns__2'}>
                        <Button
                            variant="contained"
                            onClick={handleLogin}
                            disabled={isLoading}
                        >Login</Button>

                        <Button
                            variant="text"
                            disabled={isLoading}
                            onClick={() => setError('Please contact ISN support for account assistance.')}
                        >Forgot Password</Button>
                    </div>

                    <div className={'login__footer'}>
                        © 2023 Guardian Financial — All Rights Reserved
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default Login;
