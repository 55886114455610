import {createTheme} from "@mui/material";

/**
 * The application theme.
 *
 * @type {Theme}
 */
const Theme = createTheme({
    typography: {
        fontFamily: 'Questrial, sans-serif',
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Questrial, sans-serif',
                    fontSize: '15px',
                    color: '#333'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    opacity: 0.7,
                    marginTop: '0.5em',
                    marginBottom: '0.5em'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&:not(.table__block):not(.navigation__container):not(.MuiDialog-paperScrollPaper):not(.MuiAlert-standard):not(.MuiPopover-paper):not(.MuiAutocomplete-paper)': {
                        padding: '1.5em'
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: '0',
                    background: '#333'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Questrial', sans-serif;
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                }
            `,
        },
    },
});

export default Theme;