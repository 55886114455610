import React, {useEffect, useState} from "react";

import API from "../../Generic/API";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * InspectionCompanies component.
 *
 * @returns {*}
 * @constructor
 */
function InspectionCompanies() {
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        const getResults = async () => {
            setLoading(true);
            const response = await API.send(`pay-at-close/reporting/companies`);
            setRecords(response);
            setLoading(false);
        };

        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <div className={'block__heading block__heading--bordered'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Inspection Companies {!isLoading ? `(${records.length})` : ''}</h3>
                            <p className={'text__muted'}>Your results are displayed below.</p>
                        </div>

                        <div>
                            <TextField
                                label="Search..."
                                variant="outlined"
                                sx={{width: '280px'}}
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                            <IconButton
                                onClick={() => {
                                    document.querySelector('*[data-testid="DownloadCSV-iconButton"]').click();
                                }}
                                style={{marginTop: '0.3em'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <TableContainer component={'div'} className={'transactions__list'}>
                    {isLoading && <LinearProgress />}
                    <MUIDataTable
                        className={'table__grid'}
                        data={records.map(record => {
                            return {
                                companykey: record.key,
                            };
                        })}
                        options={
                            {
                                searchText: search,
                                selectableRows: "none",
                                responsive: 'standard',
                                rowsPerPage: 100,
                                rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                filter: false,
                                search: false,
                                print: false,
                                download: true,
                                viewColumns: true,
                                customToolbar: null,
                                fixedHeader: true,
                                tableBodyHeight: 'calc(100vh - 260px)'
                            }
                        }
                        columns={[
                            {
                                name: "companykey",
                                label: "Company Key",
                                options: {
                                    filter: true,
                                    sort: true,
                                }
                            }
                        ]}
                    />
                </TableContainer>
            </Paper>
        </div>
    );
}

export default InspectionCompanies;
