import React, {Fragment, useState} from "react";

import API from "../../Generic/API";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import EmailIcon from '@mui/icons-material/Email';
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * Agreement component.
 *
 * @returns {*}
 * @constructor
 */
function AgreementBlock(props){
    const {record} = props;
    const [email, setEmail] = useState('');
    const [response, setResponse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [recipient, setRecipient] = useState('custom');
    const [isMessageOpen, setMessageOpen] = useState(false);

    /**
     * Processes the agreement send action.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);

        const response = await API.send(
            `pay-at-close/transaction/${record.id}/send-agreement`,
            'POST',
            {
                recipient,
                customRecipient: email
            }
        );

        setLoading(false);
        setResponse(response);
        setFormOpen(false);
        setMessageOpen(true);
    };


    /**
     * Reveals the note form.
     */
    const handleFormOpen = () => {
        setFormOpen(true);
    };


    /**
     * Hides the note form.
     */
    const handleFormClose = () => {
        setFormOpen(false);
    };


    /**
     * Reveals the note message.
     */
    const handleMessageOpen = () => {
        setMessageOpen(true);
    };


    /**
     * Hides the note message.
     */
    const handleMessageClose = () => {
        setMessageOpen(false);
    };

    return record.signed_agreement_url ? (
        <Paper>
            <div className={'columns__1'}>
                <div className={'d-flex__justify'}>
                    <h3 className={'text__bold'}>Agreement</h3>

                    <IconButton onClick={handleFormOpen}>
                        <EmailIcon/>
                    </IconButton>
                </div>
                <div>
                    <iframe
                        src={API.url(`pay-at-close/transaction/${record.id}/agreement#toolbar=0&navpanes=0`)}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                    />
                </div>
            </div>
            <Dialog open={isFormOpen} onClose={handleFormClose}>
                <DialogTitle>
                    <h3 className={'text__bold'}>Send Agreement</h3>
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div>
                            This form can be used to recreate and deliver an inspection's agreement
                            file using new and updated information.
                        </div>
                        <Divider/>

                        <div className={'text__bold'}>
                            To send a new agreement file, please select the recipient:
                        </div>

                        <Select
                            value={recipient}
                            label="Recipient"
                            variant={'outlined'}
                            onChange={(event) => setRecipient(event.target.value)}
                        >
                            <MenuItem value={'custom'}>Other Recipient...</MenuItem>
                            <MenuItem value={'client'}>Client</MenuItem>
                            <MenuItem value={'company'}>Title</MenuItem>
                        </Select>

                        {recipient === 'custom' && (
                            <Fragment>
                                <div>
                                    If other, please specify an email address to receive the updated agreement:
                                </div>

                                <TextField
                                    label="Email Address"
                                    variant="outlined"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </Fragment>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={doSave} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isMessageOpen}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleMessageClose}
            >
                <Alert onClose={handleMessageClose} severity={response.status} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Paper>
    ) : null;
}

export default AgreementBlock;